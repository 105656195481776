<template>
  <b-form-select class="form-control service" ref="selector" 
  :value="value" :options="options" @change="updateValue">
  </b-form-select>
</template>

<script>
export default {
  name: 'serviceSelector',
  props: [ 'value' ],
  data () {
    return {
      options: [
        { text: '◯', value: 2 },
        { text: '△', value: 1 },
        { text: '☓', value: 0 },
        { text: '-', value: '' }
      ]
    }
  },
  methods: {
    updateValue: function (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style scoped>
.service {
  padding: 6px 3px;
}
</style>
<template>
  <b-btn class="float-right ml-2" variant="warning" size="sm" @click.prevent="download" :disabled="!list || list.length < 1" v-if="isAdmin">
    <b-icon icon="download"></b-icon>
    CSVダウンロード
  </b-btn>
</template>
<script>
import { CsvConfigs } from '@/const/CsvConfigs.js'
import { mapGetters } from 'vuex'

export default {
  name: 'csvDownloadButton',
  props: {
    csvType: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      message: null,
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin'
    ]),
    headings () {
      return CsvConfigs[this.csvType] || { id: 'ID' }
    }
  },
  methods: {
    download () {
      this.$log.debug('csvDownloadButton.csvConfigs', this.csvType, CsvConfigs)
      if (!this.list || this.list.length < 1) return
      let csv = this.list.reduce((acc, cur) => (acc && acc + '\n') + this.convertItemToCsv(cur), '')
      let dData = '\ufeff' + Object.values(this.headings).reduce((acc, cur) => acc + ',' + cur) + '\n' + csv
      let blob = new Blob([dData], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = this.csvType + '_' + Date.now() + '.csv'
      link.click()
    },
    convertItemToCsv (item) {
      return Object.keys(this.headings).reduce((acc, cur) => (acc && (acc + ',')) + (this.escape(this.getV(item, cur)) || ''), '')
    },
    getV (m, k) {
      const i = k.indexOf('.')
      if (i > 0) {
        // let w = k.split('.')
        // return m[w[0]] && m[w[0]][w[1]]
        const c = m[k.substring(0, i)]
        return c && this.getV(c, k.substring(i + 1))
      }
      return m[k]
    },
    escape (s) {
      return s && String(s).replace(/\r?\n/g, '').replace(',', '、')
    }
  }
}
</script>
<style scoped>

</style>

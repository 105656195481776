<template>
  <div>
    <NavbarHeaderComp></NavbarHeaderComp>
    <b-container>
      <b-row class="mt-2 mb-3">
        <b-col class="border-bottom">
          <h6 class="hello">
            アカウント情報
          </h6>
        </b-col>
      </b-row>
      <myAccountDetail></myAccountDetail>
      <FooterComp></FooterComp>
    </b-container>
  </div>
</template>

<script>
import NavbarHeaderComp from '@/components/NavbarHeader'
import myAccountDetail from '@/components/MyAccountDetail'
import FooterComp from '@/components/modules/FooterComp'
// import moment from 'moment'

export default {
  name: 'myAccount',
  data () {
    return {
    }
  },
  components: {
    NavbarHeaderComp,
    myAccountDetail,
    FooterComp
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
const googleMapsUrl = function ({ title, placeId, position }) {
  if (placeId) {
    return 'https://www.google.com/maps/search/?api=1&query=' + title + '&query_place_id=' + placeId
  } else if (position && position.lat) {
    return 'https://www.google.com/maps/search/?api=1&query=' + position.lat + ',' + position.lng + '&z=15'
  }
  return 'https://www.google.com/maps/search/?api=1&query=' + title + '&z=15'
}

export { googleMapsUrl }

<template>
  <div>
    <b-row>
      <b-col md="6">
        <BasicInfoPanel></BasicInfoPanel>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="bg-success text-white">
            <i class="fa fa-stethoscope" aria-hidden="true"></i><span> 診療</span>
            <div class="pull-right">
              <ServiceEditModalButton @onUpdated="refresh"></ServiceEditModalButton>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form horizontal>
              <b-form-group label="診療科目" label-cols-sm="2" label-cols-lg="3" label-class="font-weight-bold">
                <div>{{item.services.summary}}</div>
              </b-form-group>
              <b-form-group label="診療日" label-cols-sm="2" label-cols-lg="3" label-class="font-weight-bold">
                <BusinessDaysTable></BusinessDaysTable>
              </b-form-group>
              <b-form-group label="診療日備考" label-cols-sm="2" label-cols-lg="3" label-class="font-weight-bold">
                <b-card bg-variant="light">{{item.business_days.memo}}</b-card>
              </b-form-group>
              <hr>
              <b-form-group label="備考" label-cols-sm="2" label-cols-lg="3" label-class="font-weight-bold">
                <b-card bg-variant="light">{{item.services.memo}}</b-card>
                <p class="small mt-2">※連絡する際の要望、注意点、病院の特定など、ケアマネージャーに伝えたいことなど</p>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col lg="6">
        <googleMapComp></googleMapComp>
      </b-col>
      <b-col lg="6">
        <b-card header="更新履歴" header-class="bg-info text-white">
          <b-list-group>
            <b-list-group-item class="small">{{formatDate(item.created_at)}} 初期登録</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import BasicInfoPanel from '@/components/modules/BasicInfoPanelComp'
import BusinessDaysTable from '@/components/modules/BusinessDaysTableComp'
import ServiceEditModalButton from '@/components/modules/HospitalServiceEditModalButton'
import googleMapComp from '@/components/modules/GoogleMapComp'

export default {
  name: 'hospitalDetail',
  components: {
    BasicInfoPanel,
    BusinessDaysTable,
    ServiceEditModalButton,
    googleMapComp
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    }
  },
  methods: {
    formatDate (value, fmt = 'YYYY/MM/DD') {
      return (value == null) ? '--' : moment(value, 'YYYY-MM-DD').format(fmt)
    },
    refresh (msg) {
      this.$bvToast.toast(msg || '施設を更新しました。', { variant: 'success' })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.form-group { margin-bottom: 5px; }
</style>

<template>
  <span>
    <span v-if="value === '2' || value === 2">◯</span>
    <span v-else-if="value === '1' || value === 1">△</span>
    <span v-else-if="value === '0' || value === 0">☓</span>
    <span v-else-if="value === '' || !value">-</span>
    <span v-else>{{value}}</span>
  </span>
</template>

<script>
export default {
  name: 'serviceViewComp',
  props: [ 'value' ],
  data () {
    return {
    }
  }
}
</script>

<style scoped>
</style>


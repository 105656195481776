import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Login from '@/pages/Login'
import About from '@/pages/About'
import AccountAction from '@/pages/AccountAction'
import Dashboard from '@/pages/Dashboard'
import RegisterUser from '@/pages/RegisterUser'
import UserRegistered from '@/pages/UserRegistered'
import ForgotMailSent from '@/pages/ForgotMailSent'
import MyAccount from '@/pages/MyAccount'
import Hospitals from '@/pages/Hospitals'
import HospitalView from '@/pages/HospitalView'
import HospitalMaps from '@/pages/HospitalMaps'
import Clinics from '@/pages/Clinics'
import ClinicView from '@/pages/ClinicView'
import ClinicMaps from '@/pages/ClinicMaps'
import DentalOffices from '@/pages/DentalOffices'
import DentalOfficeView from '@/pages/DentalOfficeView'
import DentalOfficeMaps from '@/pages/DentalOfficeMaps'
import Pharmacies from '@/pages/Pharmacies'
import PharmacyView from '@/pages/PharmacyView'
import PharmacyMaps from '@/pages/PharmacyMaps'
import VisitingNursingStations from '@/pages/VisitingNursingStations'
import VisitingNursingStationView from '@/pages/VisitingNursingStationView'
import VisitingNursingStationMaps from '@/pages/VisitingNursingStationMaps'
import HomeVisitCareSupportServices from '@/pages/HomeVisitCareSupportServices'
import HomeVisitCareSupportServiceView from '@/pages/HomeVisitCareSupportServiceView'
import HomeVisitCareSupportServiceMaps from '@/pages/HomeVisitCareSupportServiceMaps'
import VisitingCareServices from '@/pages/VisitingCareServices'
import VisitingCareServiceView from '@/pages/VisitingCareServiceView'
import VisitingCareServiceMaps from '@/pages/VisitingCareServiceMaps'
import RehabilitationServices from '@/pages/RehabilitationServices'
import RehabilitationServiceView from '@/pages/RehabilitationServiceView'
import RehabilitationServiceMaps from '@/pages/RehabilitationServiceMaps'
import DayCareSupportServices from '@/pages/DayCareSupportServices'
import DayCareSupportServiceView from '@/pages/DayCareSupportServiceView'
import DayCareSupportServiceMaps from '@/pages/DayCareSupportServiceMaps'
import CommunityBasedSupportCenters from '@/pages/CommunityBasedSupportCenters'
import CommunityBasedSupportCenterView from '@/pages/CommunityBasedSupportCenterView'
import CommunityBasedSupportCenterMaps from '@/pages/CommunityBasedSupportCenterMaps'
import SmallMultiHomeCareSupportServices from '@/pages/SmallMultiHomeCareSupportServices'
import SmallMultiHomeCareSupportServiceView from '@/pages/SmallMultiHomeCareSupportServiceView'
import SmallMultiHomeCareSupportServiceMaps from '@/pages/SmallMultiHomeCareSupportServiceMaps'

import SpecialElderlyNursingHomes from '@/pages/SpecialElderlyNursingHomes'
import SpecialElderlyNursingHomeMaps from '@/pages/SpecialElderlyNursingHomeMaps'
import SpecialElderlyNursingHomeView from '@/pages/SpecialElderlyNursingHomeView'
import GeriatricHealthServicesFacilities from '@/pages/GeriatricHealthServicesFacilities'
import GeriatricHealthServicesFacilityMaps from '@/pages/GeriatricHealthServicesFacilityMaps'
import GeriatricHealthServicesFacilityView from '@/pages/GeriatricHealthServicesFacilityView'
import Hiratsukas from '@/pages/Hiratsukas'
import HiratsukaView from '@/pages/HiratsukaView'
import HiratsukaMaps from '@/pages/HiratsukaMaps'

Vue.use(Router)

const debug = process.env.NODE_ENV !== 'prod'

function requireAuth (to, from, next) {
  var currentUser = store.getters.currentUser
  debug && Vue.$log.debug('requireAuth', currentUser)
  if (!currentUser.uid || !currentUser.verified) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    debug && Vue.$log.debug('next', next)
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/accountAction',
    name: 'AccountAction',
    component: AccountAction
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter (to, from, next) {
      store.dispatch('signOut').then(() => {
        next('/login')
      })
    }
  },
  {
    path: '/registeruser',
    name: 'RegisterUser',
    component: RegisterUser
  },
  {
    path: '/registeruser/done',
    name: 'UserRegistered',
    component: UserRegistered
  },
  {
    path: '/forgotmailsent',
    name: 'ForgotMailSent',
    component: ForgotMailSent
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/myaccount',
    name: 'myAccount',
    component: MyAccount,
    beforeEnter: requireAuth
  },
  {
    path: '/hospitals',
    name: 'Hospitals',
    component: Hospitals,
    beforeEnter: requireAuth
  },
  {
    path: '/hospital/maps',
    name: 'HospitalMaps',
    component: HospitalMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/hospitals/:id',
    name: 'Hospital',
    component: HospitalView,
    beforeEnter: requireAuth
  },
  {
    path: '/clinics',
    name: 'Clinics',
    component: Clinics,
    beforeEnter: requireAuth
  },
  {
    path: '/clinic/maps',
    name: 'ClinicMaps',
    component: ClinicMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/clinics/:id',
    name: 'Clinic',
    component: ClinicView,
    beforeEnter: requireAuth
  },
  {
    path: '/dentaloffices',
    name: 'DentalOffices',
    component: DentalOffices,
    beforeEnter: requireAuth
  },
  {
    path: '/dentaloffice/maps',
    name: 'DentalOfficeMaps',
    component: DentalOfficeMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/dentaloffices/:id',
    name: 'DentalOffice',
    component: DentalOfficeView,
    beforeEnter: requireAuth
  },
  {
    path: '/pharmacies',
    name: 'Pharmacies',
    component: Pharmacies,
    beforeEnter: requireAuth
  },
  {
    path: '/pharmacy/maps',
    name: 'PharmacyMaps',
    component: PharmacyMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/pharmacies/:id',
    name: 'Pharmacy',
    component: PharmacyView,
    beforeEnter: requireAuth
  },
  {
    path: '/visitnurses',
    name: 'VisitingNursingStations',
    component: VisitingNursingStations,
    beforeEnter: requireAuth
  },
  {
    path: '/visitnurse/maps',
    name: 'VisitingNursingStationMaps',
    component: VisitingNursingStationMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/visitnurses/:id',
    name: 'VisitingNursingStation',
    component: VisitingNursingStationView,
    beforeEnter: requireAuth
  },
  {
    path: '/homecares',
    name: 'HomeVisitCareSupportServices',
    component: HomeVisitCareSupportServices,
    beforeEnter: requireAuth
  },
  {
    path: '/homecare/maps',
    name: 'HomeVisitCareSupportServiceMaps',
    component: HomeVisitCareSupportServiceMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/homecares/:id',
    name: 'HomeVisitCareSupportService',
    component: HomeVisitCareSupportServiceView,
    beforeEnter: requireAuth
  },
  {
    path: '/visitcares',
    name: 'VisitingCareServices',
    component: VisitingCareServices,
    beforeEnter: requireAuth
  },
  {
    path: '/visitcare/maps',
    name: 'VisitingCareServiceMaps',
    component: VisitingCareServiceMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/visitcares/:id',
    name: 'VisitingCareService',
    component: VisitingCareServiceView,
    beforeEnter: requireAuth
  },
  {
    path: '/rehabilitations',
    name: 'RehabilitationServices',
    component: RehabilitationServices,
    beforeEnter: requireAuth
  },
  {
    path: '/rehabilitation/maps',
    name: 'RehabilitationServiceMaps',
    component: RehabilitationServiceMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/rehabilitations/:id',
    name: 'RehabilitationService',
    component: RehabilitationServiceView,
    beforeEnter: requireAuth
  },
  {
    path: '/daycares',
    name: 'DayCareSupportServices',
    component: DayCareSupportServices,
    beforeEnter: requireAuth
  },
  {
    path: '/daycare/maps',
    name: 'DayCareSupportServiceMaps',
    component: DayCareSupportServiceMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/daycares/:id',
    name: 'DayCareSupportService',
    component: DayCareSupportServiceView,
    beforeEnter: requireAuth
  },
  {
    path: '/communities',
    name: 'CommunityBasedSupportCenters',
    component: CommunityBasedSupportCenters,
    beforeEnter: requireAuth
  },
  {
    path: '/community/maps',
    name: 'CommunityBasedSupportCenterMaps',
    component: CommunityBasedSupportCenterMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/communities/:id',
    name: 'CommunityBasedSupportCenter',
    component: CommunityBasedSupportCenterView,
    beforeEnter: requireAuth
  },
  {
    path: '/smallhomecares',
    name: 'SmallMultiHomeCareSupportServices',
    component: SmallMultiHomeCareSupportServices,
    beforeEnter: requireAuth
  },
  {
    path: '/smallhomecare/maps',
    name: 'SmallMultiHomeCareSupportServiceMaps',
    component: SmallMultiHomeCareSupportServiceMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/smallhomecares/:id',
    name: 'SmallMultiHomeCareSupportService',
    component: SmallMultiHomeCareSupportServiceView,
    beforeEnter: requireAuth
  },
  {
    path: '/smallmultihomenurses',
    name: 'SmallMultiHomeNurseServices',
    component: () => import ('@/pages/SmallMultiHomeNurseServices'),
    beforeEnter: requireAuth
  }, {
    path: '/smallmultihomenurse/maps',
    name: 'SmallMultiHomeNurseServiceMaps',
    component: () => import ('@/pages/SmallMultiHomeNurseServiceMaps'),
    beforeEnter: requireAuth
  }, {
    path: '/smallmultihomenurse/:id',
    name: 'SmallMultiHomeNurseService',
    component: () => import ('@/pages/SmallMultiHomeNurseServiceView'),
    beforeEnter: requireAuth
  },
  {
    path: '/eldershomes',
    name: 'SpecialElderlyNursingHomes',
    component: SpecialElderlyNursingHomes,
    beforeEnter: requireAuth
  },
  {
    path: '/eldershome/maps',
    name: 'SpecialElderlyNursingHomeMaps',
    component: SpecialElderlyNursingHomeMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/eldershomes/:id',
    name: 'SpecialElderlyNursingHome',
    component: SpecialElderlyNursingHomeView,
    beforeEnter: requireAuth
  },
  {
    path: '/grhealthes',
    name: 'GeriatricHealthServicesFacilities',
    component: GeriatricHealthServicesFacilities,
    beforeEnter: requireAuth
  },
  {
    path: '/grhealth/maps',
    name: 'GeriatricHealthServicesFacilityMaps',
    component: GeriatricHealthServicesFacilityMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/grhealthes/:id',
    name: 'GeriatricHealthServicesFacility',
    component: GeriatricHealthServicesFacilityView,
    beforeEnter: requireAuth
  },
  {
    path: '/hiratsukas',
    name: 'Hiratsukas',
    component: Hiratsukas,
    beforeEnter: requireAuth
  },
  {
    path: '/hiratsuka/maps',
    name: 'HiratsukaMaps',
    component: HiratsukaMaps,
    beforeEnter: requireAuth
  },
  {
    path: '/hiratsukas/:id',
    name: 'Hiratsuka',
    component: HiratsukaView,
    beforeEnter: requireAuth
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

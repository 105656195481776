<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col id="loginbox" cols="6" md="6" sm="8" class="mt-5">
        <b-card :header="title" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <p>パスワード再設定メールを送信しました</p>
          <p>メールが届かない場合には、しばらく待ってから担当課までご連絡ください。</p>
          <router-link to='login' replace>戻る</router-link>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'forgotMailSent',
  data () {
    return {
      title: '伊勢原市在宅医療と介護連携情報WEB'
    }
  }
}
</script>

<style scoped>
#mainbox { margin-top: 50px; }
</style>
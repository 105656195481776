<template>
  <div id="pageTitleBar" class="row">
    <div class="col-lg-8 col-md-7 col-sm-6">
      <h4 id="pageTitle">
        <i :class="iconClass" class="mr-2"></i> <router-link :to="{ name: modelName }">{{categoryDef.title}}</router-link> 
        <small>/ 
          <span v-if='screen === "地図"'>
            <router-link :to="{ name: modelName }">一覧</router-link> | <strong>{{screen}}</strong> 
          </span>
          <span v-else>
            <strong>{{screen}}</strong> | <router-link :to="'/'+categoryDef['short_name']+'/maps'">地図</router-link>
          </span>
        </small>
      </h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { categoryIcon } from '@/utils/CategoryUtils'

export default {
  name: 'breadCrumbList',
  props: [ 'screen' ],
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'categoryDef'
    ]),
    iconClass: function () {
      return categoryIcon(this.$store.getters.category)
    },
    modelName: function () {
      var m = this.$store.getters.categoryDef.model
      return m && (m.charAt(0).toUpperCase() + m.slice(1))
    }
  }
}
</script>

<!-- Add "scoped" attribute to    limit CSS to this component only -->

<style scoped>
#pageTitleBar { padding-top: 15px; }
#pageTitle { font-size: 20px; }
@media (max-width: 767px) {
  #pageTitle { font-size: 18px; }
}
</style>

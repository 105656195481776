<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-list-group>
            <b-list-group-item>
              <span class="small mr-2">お名前:</span>
              <strong>{{currentUser.name}}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span class="small mr-2">所属または会社名:</span>
              <strong>{{currentUser.company}}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span class="small mr-2">メールアドレス:</span>
              <strong>{{currentUser.email}}</strong>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'myAccountPanel',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  }
}
</script>

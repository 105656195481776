<!-- app -->
<template>
  <div v-if="isEditable">
    <div>
      <b-btn variant="primary" size="sm" @click="openModal"><i class="fas fa-pencil-alt mr-1"></i> 編集</b-btn>
    </div>
    <b-modal ref="editModal" size="xl"
      header-bg-variant="success"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title"><i :class="iconClass"></i> {{title}}-編集 [基本情報]</h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <b-form horizontal>
          <b-form-group label-for="management_corporation" label="運営団体/法人名:" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="management_corporation" type="text" class="form-control" v-model="edit.management_corporation" placeHolder="100文字以内"></b-input>
          </b-form-group>
          <b-form-group label-for="name" label="名称(*必須):" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="name" type="text" class="form-control" v-model="edit.name" placeHolder="(*必須)100文字以内"></b-input>
          </b-form-group>
          <b-form-group label-for="director_name" :label="director" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="director_name" type="text" class="form-control" v-model="edit.director_name" placeHolder="肩書/氏名 100文字以内"></b-input>
          </b-form-group>
          <b-form-group label-for="zipCode" label="住所" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-col sm="6" class="mt-1">
              <b-input-group prepend="〒">
                <b-input id="zipCode" type="text" class="form-control" v-model="address.zipCode" placeHolder="郵便番号 999-9999"></b-input>
              </b-input-group>
            </b-col>
            <b-col sm="6" class="mt-1">
              <b-btn variant="secondary" size="sm" @click="suggestGeocode">GoogleMap確認</b-btn>
              <span class="small" v-if="address.placeId">{{address.placeId}}</span>
              <span class="small" v-else-if="address.latitude">{{address.latitude}},{{address.longitude}}</span>
              <span class="small" v-else>住所を入力して確認を押してください</span>
            </b-col>
            <b-col sm="9" class="mt-1">
              <b-select v-model="address.prefecture">
                <option v-for="pref in prefectures" :key="pref">{{pref}}</option>
              </b-select>
            </b-col>
            <b-col sm="9" class="mt-1">
              <b-input id="line1" type="text" v-model="address.line1" placeHolder="市区町村"></b-input>
            </b-col>
            <b-col sm="9" class="mt-1">
              <b-input id="line2" type="text" v-model="address.line2" placeHolder="地名番地"></b-input>
            </b-col>
            <b-col sm="9" class="mt-1">
              <b-input id="line3" type="text" v-model="address.line3" placeHolder="建物名など"></b-input>
            </b-col>
          </b-form-group>
          <b-form-group label-for="tel" label="TEL" label-cols-sm="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-col class="mt-1">
              <b-input-group prepend="TEL">
                <b-input id="tel" type="text" class="form-control" v-model="edit.tel" placeHolder="0463-XX-XXXX"></b-input>
              </b-input-group>
            </b-col>
            <b-col class="mt-1">
              <b-input-group prepend="部署">
                <b-input id="tel_section" type="text" class="form-control" v-model="edit.tel_section" placeHolder="例) 代表、総合受付、担当課など"></b-input>
              </b-input-group>
            </b-col>
            <b-col class="mt-1">
              <div>
                <span>連絡優先</span>
                <toggle-button id="priority_tel" class="priority" :value="priority_tel" :labels="{checked: '高', unchecked: '低'}" :width="100" :height="30" :sync="true" @change="onPriorityChanged('priority_tel')" />
              </div>
            </b-col>
          </b-form-group>
          <b-form-group label-for="fax" label="FAX" label-cols-sm="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-col class="mt-1">
              <b-input-group prepend="FAX">
                <b-input id="fax" type="text" class="form-control" v-model="edit.fax" placeHolder="0463-XX-XXXX"></b-input>
              </b-input-group>
            </b-col>
            <b-col class="mt-1">
              <b-input-group prepend="部署">
                <b-input id="fax_section" type="text" class="form-control" v-model="edit.fax_section" placeHolder="例) 代表、総合受付、担当課など"></b-input>
              </b-input-group>
            </b-col>
            <b-col class="mt-1">
              <div>
                <span>連絡優先</span>
                <toggle-button id="priority_fax" class="priority" :value="priority_fax" :labels="{checked: '高', unchecked: '低'}" :width="100" :height="30" :sync="true" @change="onPriorityChanged('priority_fax')" />
              </div>
            </b-col>
          </b-form-group>
          <b-form-group label-for="email" label="Email" label-cols-sm="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-col class="mt-1">
              <b-input-group prepend="email">
                <b-input id="email" type="text" class="form-control" v-model="edit.email" placeHolder="xxx@xxx.xxx"></b-input>
              </b-input-group>
            </b-col>
            <b-col class="mt-1">
              <b-input-group prepend="部署">
                <b-input id="email_section" type="text" class="form-control" v-model="edit.email_section" placeHolder="例) 代表、総合受付、担当課など"></b-input>
              </b-input-group>
            </b-col>
          </b-form-group>
          <b-form-group label-for="url" label="Web" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="url" type="text" class="form-control" v-model="edit.url" placeHolder="https://xxx.xxx.xxx"></b-input>
          </b-form-group>
          <b-form-group label-for="memo" label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-form-textarea v-model="edit.memo" rows="3"></b-form-textarea>
          </b-form-group>
          <b-form-group label-for="url" label="表示順" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="url" type="number" class="form-control" v-model="edit.view_order" placeHolder="0-999"></b-input>
          </b-form-group>
        </b-form>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="primary" class="mr-2" @click="onSave">保存して更新</b-btn>
          <b-btn variant="secondary" @click="onClose">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Prefectures from '@/const/Prefectures'
import { categoryIcon } from '@/utils/CategoryUtils'
import { mapGetters } from 'vuex'
import googleMapsApi from '@/api/googleMaps'

export default {
  name: 'basicInfoEditModalButton',
  components: {
  },
  data () {
    return {
      showModal: true,
      edit: {
        management_corporation: ' ',
        name: '',
        director_name: '',
        tel: '',
        tel_section: '',
        fax: '',
        fax_section: '',
        email: '',
        email_section: '',
        url: '',
        address_summary: '',
        memo: '',
        view_order: 0
      },
      address: {
        zipCode: '',
        prefecture: '',
        line1: '',
        line2: '',
        line3: '',
        latitude: null,
        longitude: null,
        placeId: ''
      },
      cooperation_priority: {
        tel: 1,
        fax: 2
      },
      priority_tel: true,
      priority_fax: false,
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem',
      'currentUser'
    ]),
    title: function () {
      return this.$store.getters.categoryDef.title
    },
    iconClass: function () {
      return categoryIcon(this.$store.getters.category)
    },
    director: function () {
      return this.$store.getters.categoryDef.director
    },
    isEditable: function () {
      return this.isAdmin || (this.currentUser.permits && this.currentUser.permits[this.currentItem.id])
    },
    prefectures: function () { return Prefectures.Prefectures }
  },
  mounted: function () {
    this.loadItem()
  },
  methods: {
    loadItem: function () {
      this.error = null
      _.keys(this.edit).forEach(e => { this.edit[e] = this.currentItem[e] })
      // Object.assign(this.edit, this.currentItem) // => contactなどもコピーされる
      this.address = _.cloneDeep(this.currentItem.address)
      this.cooperation_priority = _.cloneDeep(this.currentItem.cooperation_priority)
      this.priority_tel = this.cooperation_priority.tel === 1
      this.priority_fax = this.cooperation_priority.fax === 1
    },
    onPriorityChanged: function () {
      this.$data['priority_tel'] = !this.$data['priority_tel']
      this.$data['priority_fax'] = !this.$data['priority_fax']
    },
    openModal: function () {
      this.loadItem()
      this.$refs['editModal'].show()
    },
    validate: function () {
      this.error = null
      if (!this.edit.name.trim()) {
        this.error = '名称を入力してください。'
      }
      if (this.address.line1) {
        var summary = this.address.line1
        summary += this.address.line2 || ''
        summary += this.address.line3 ? (' ' + this.address.line3) : ''
        this.edit.address_summary = summary
      }
      this.cooperation_priority.tel = this.priority_tel ? 1 : 2
      this.cooperation_priority.fax = this.priority_fax ? 1 : 2
      this.edit.view_order = this.edit.view_order || 0
    },
    suggestGeocode: function () {
      if (this.address.line1) {
        var summary = this.address.prefecture + this.address.line1
        summary += this.address.line2 || ''
        // summary += this.address.line3 ? (' ' + this.address.line3) : ''
        this.edit.address_summary = summary
      }
      this.$log.info(this.edit.address_summary)
      googleMapsApi.getGeocode(this.edit.address_summary + ' ').then(res => {
        this.$log.debug('res=', res.json.results)
        if (res.json.results.length < 1) { return }
        var result = res.json.results[0]
        var loc = result.geometry.location
        Vue.set(this.$data.address, 'latitude', loc.lat)
        Vue.set(this.$data.address, 'longitude', loc.lng)
        Vue.set(this.$data.address, 'placeId', result.place_id)
        var zipCode = _.find(result.address_components, { 'types': ['postal_code'] })
        this.$log.debug(zipCode)
        if (zipCode) {
          Vue.set(this.$data.address, 'zipCode', zipCode['long_name'])
          this.$log.debug(this.$data.address.zipCode)
        }
      }).catch(err => {
        this.$log.error(err)
      })
      /*
      googleMapsApi.getPlaces(this.edit.name, this.edit.address_summary).then(res => {
        console.log(res.json.results)
        this.googleMaps.place = true
      })
      */
    },
    onSave: function () {
      this.validate()
      if (this.error) return
      this.$log.debug('currentItem', this.currentItem)
      var _item = _.clone(this.currentItem)

      Object.assign(_item, this.edit)
      Object.assign(_item.address, this.address)
      Object.assign(_item.cooperation_priority, this.cooperation_priority)

      _item.management_corporation = _item.management_corporation || ''

      this.$log.info('updating', _item)
      this.$store.dispatch('updateItem', { item: _item }).then(() => {
        this.$log.info('updated!')
        this.$refs['editModal'].hide()
        this.$emit('onUpdated')
      }).catch((error) => {
        this.$log.error(error)
        this.$data.error = error.messages
      })
    },
    onClose: function () {
      this.$refs['editModal'].hide()
    }
  }
}
</script>

<style scoped>
.vue-js-switch.priority {
  font-size: 14px !important;
  margin-top: 3px;
}
</style>
<template>
  <div id='map-canvas'>
    <gmap-map
      :center="position"
      :zoom="15"
      style="width: 100%; height: 400px"
    >
      <gmap-info-window 
        :options="infoWindowParams.options" 
        :position="infoWindowParams.position" 
        :opened="infoWindowParams.opened"
        :place="{ placeId: infoWindowParams.item.placeId }"
        :zIndex="1000"
        @closeclick="infoWindowParams.opened=false">
        <h5><strong>{{infoWindowParams.item.title}}</strong></h5>
        <div>{{infoWindowParams.item.address}}</div>
        <div>TEL: {{infoWindowParams.item.tel}}</div>
        <a :href="toGoogleMapsUrl" target="_blank">Googleマップで見る</a>
      </gmap-info-window>
      <gmap-marker v-if="marker.title"
        :position="marker.position"
        :title="marker.title"
        :clickable="true"
        @click="onMarkerEvent"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import { googleMapsUrl } from '@/utils/GoogleMapsUtils'

export default {
  name: 'googleMapComp',
  data () {
    return {
      item: {},
      center: {},
      marker: {
        position: {},
        title: ''
      },
      infoWindowParams: {
        options: {
          pixelOffset: {
            width: 0,
            height: -35
          },
          zIndex: 1000
        },
        position: { lat: 35.4036602, lng: 139.3148285 },
        opened: false,
        item: {
          id: '',
          title: '',
          address: '',
          tel: '',
          category_id: '',
          placeId: ''
        }
      }
    }
  },
  computed: {
    _center: function () {
      return {
        lat: 35.4036602,
        lng: 139.3148285
      }
    },
    position: function () {
      return this.item && this.item.address && this.item.address.latitude ? this.marker.position : this._center
    },
    toGoogleMapsUrl: function () {
      return googleMapsUrl({
        title: this.infoWindowParams.item.title,
        placeId: this.infoWindowParams.item.placeId,
        position: this.infoWindowParams.position
      })
    }
  },
  methods: {
    toMarker: function (item) {
      return item && item.address && item.address.latitude ? {
        position: {
          lat: parseFloat(item.address.latitude),
          lng: parseFloat(item.address.longitude)
        },
        title: item.name,
        category: item.category_id,
        address: item.address_summary,
        tel: item.tel,
        placeId: item.address.placeId || ''
      } : {}
    },
    loadMarker: function (item) {
      return this.toMarker(item)
    },
    onMarkerEvent: function () {
      this.infoWindowParams.opened = true
    }
  },
  created: function () {
    this.item = this.$store.getters.currentItem
    this.marker = this.loadMarker(this.item)
    Object.assign(this.infoWindowParams.item, this.marker)
    this.infoWindowParams.position = this.marker.position
  }
}
</script>

import axios from 'axios'

// TODO config
const baseUrl = 'https://us-central1-isehara-comedicare.cloudfunctions.net'

// axios.defaults.withCredentials = true
// axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

axios.defaults.baseURL = baseUrl

export default {
  setToken (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  },

  getCustomToken (token) {
    var instance = axios.create()
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
    return instance.get('/getCustomToken').then(res => {
      if (res.status === 200) {
        // console.log('getCustomToken', res.data.token)
        return res.data.token
      } else {
        throw new Error('response error')
      }
    })
  },

  verifyToken () {
    return axios.get('/verifyToken').then(res => {
      if (res.status === 200) {
        return res.data
      } else {
        throw new Error('response error')
      }
    })
  }
}

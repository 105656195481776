<template>
  <b-container fluid>
    <b-row class="justify-content-md-center">
      <b-col id="loginbox" cols="12" md="8" sm="12" lg="6" class="mt-5" v-show="loginBoxVisible">
        <b-card :header="title" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <b-card-body>
            <b-alert variant="danger" :show="message != null">{{message}}</b-alert>
            <b-form horizontal @submit.stop.prevent>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text><i class="fas fa-envelope"></i></b-input-group-text>
                </template>
                <b-form-input id="email" type="email" v-model="login.email" placeholder="メールアドレス" :state="login.isValidEmail"></b-form-input>
              </b-input-group>
              <b-input-group class="mt-2">
                <template #prepend>
                  <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                </template>
                <b-form-input id="login-password" type="password" v-model="login.password" placeholder="パスワード" :state="login.isValidPassword"></b-form-input>
              </b-input-group>
              <!-- Button -->
              <div class="mt-4">
                <b-btn variant="success" :disabled="loading" @click.prevent="onLoginWithWaiting">
                  <b-spinner variant="light" small v-if="loading"></b-spinner>
                  <span v-else>
                    <i class="fa fa-lg fa-sign-in-alt"></i>
                    ログイン
                  </span>
                </b-btn>
              </div>
            </b-form>
          </b-card-body>
          <b-card-footer>
            <p class="font-weight-light" style="font-size:85%;">
              アカウントをまだお持ちでない方は、利用申請を行ってください。
              <router-link to='/registeruser'>
                利用申請はこちら
              </router-link>
            </p>
            <p class="font-weight-light" style="font-size:85%;">
              <b-link href="#" variant="link" size="sm" @click.prevent="openForgot">パスワードを忘れたとき</b-link>
            </p>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col id="loginbox" cols="12" md="8" sm="12" lg="6" class="mt-5" v-show="forgotBoxVisible">
        <b-card :header="title" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <b-card-body>
            <b-alert variant="danger" :show="forgotMessage != null">{{forgotMessage}}</b-alert>
            <b-form horizontal @submit.stop.prevent>
              <h5>パスワード再設定</h5>
              <p>登録したメールアドレスにパスワード再設定のメールを送信します。</p>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text><i class="fas fa-envelope"></i></b-input-group-text>
                </template>
                <b-form-input id="registered-email" type="email" v-model="forgot.email" placeholder="登録したメールアドレス" :state="login.isValidEmail"></b-form-input>
              </b-input-group>
              <!-- Button -->
              <div class="mt-4">
                <b-btn variant="success" :disabled="loading" @click.prevent="onForgot">
                  <i class="fa fa-lg fa-sign-in-alt"></i>
                  上記にメールを送信する
                </b-btn>
              </div>
            </b-form>
          </b-card-body>
          <b-card-footer>
            <p class="font-weight-light" style="font-size:85%;">
              <b-link href="#" variant="link" size="sm" @click.prevent="openLogin">サインインする</b-link>
            </p>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <p class="text-center mt-5 copyright">&copy; 2021 Isehara city All Rights Reserved.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Firebase from '@/api/firebase'

export default {
  name: 'login',
  data () {
    return {
      title: '伊勢原市在宅医療と介護連携情報WEB',
      message: null,
      loading: false,
      login: {
        email: null,
        password: null,
        isErrorEmail: null,
        isErrorPassword: null
      },
      forgotMessage: null,
      forgot: {
        email: null
      },
      loginBoxVisible: true,
      forgotBoxVisible: false
    }
  },
  methods: {
    validateEmail: function (email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)
    },
    validatePassword: function (password) {
      return password
    },
    openLogin: function () {
      this.loginBoxVisible = true
      this.forgotBoxVisible = false
    },
    openForgot: function () {
      this.loginBoxVisible = false
      this.forgotBoxVisible = true
    },
    validate: function () {
      this.message = null
      this.login.isErrorEmail = !this.validateEmail(this.login.email)
      this.login.isErrorPassword = !this.validatePassword(this.login.password)
      if (this.login.isErrorEmail) {
        this.message = 'メールアドレスを正しく入力してください。(F)'
      } else if (this.login.isErrorPassword) {
        this.message = 'パスワードを入力してください。(F)'
      }
      return !this.message
    },
    onLoginWithWaiting: function () {
      if (!this.validate()) return
      if (this.loading) return
      var that = this
      this.loading = true
      this.onLoginWithEmailAndPassword().then(() => {
        that.loading = false
      })
    },
    onLoginWithEmailAndPassword: function () {
      return this.$store.dispatch('signInWithEmailAndPassword', { email: this.login.email, password: this.login.password }).then(() => {
        this.$log.info('dispatch signInWithEmailAndPassword: loginned')
        this.$router.replace('/dashboard').catch(() => {})
      }).catch((error) => {
        this.loading = false
        this.$log.info('dispatch signInWithEmailAndPassword: Error', error)
        this.message = Firebase._translateMessage(error.code)
      })
    },
    onForgot: function () {
      if (!this.validateEmail(this.forgot.email)) {
        this.forgotMessage = 'メールアドレスを正しく入力してください。'
        return
      }
      this.loading = true
      this.$log.debug('onForgot', this.forgot.email)
      Firebase.sendPasswordResetEmail(this.forgot.email).then(() => {
        this.loading = false
        this.$router.replace('/forgotmailsent')
      }).catch(error => {
        this.forgotMessage = error
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.copyright {
  font-size: 0.9em;
}
</style>

const basic = {
  'id': 'ID',
  'management_corporation': '法人',
  'name': '名称',
  'director_name': '代表/院長',
  'address.zipCode': '〒',
  'address_summary': '住所',
  'tel': 'TEL',
  'tel_section': 'TEL部署',
  'cooperation_priority.tel': '連携優先順位TEL',
  'fax': 'FAX',
  'fax_section': 'FAX部署',
  'cooperation_priority.fax': '連携優先順位FAX',
  'email': 'email',
  'email_section': 'email部署',
  'url': 'Web',
  'memo': '備考',
  'view_order': '表示順',
  'contact.name': '連携窓口-連絡先',
  'contact.memo': '連携窓口-備考',
  'opened': '公開',
  'author.name': '登録者',
  'created_at': '初期登録日',
}
const businessDays = {
  'business_days.business_hours.am': '診療-診療日-受付-午前',
  'business_days.monday.am': '診療-診療日-月-午前',
  'business_days.tuesday.am': '診療-診療日-火-午前',
  'business_days.wednesday.am': '診療-診療日-水-午前',
  'business_days.thursday.am': '診療-診療日-木-午前',
  'business_days.friday.am': '診療-診療日-金-午前',
  'business_days.saturday.am': '診療-診療日-土-午前',
  'business_days.sunday.am': '診療-診療日-日-午前',
  'business_days.public_holiday.am': '診療-診療日-祝-午前',
  'business_days.business_hours.pm': '診療-診療日-受付-午後',
  'business_days.monday.pm': '診療-診療日-月-午後',
  'business_days.tuesday.pm': '診療-診療日-火-午後',
  'business_days.wednesday.pm': '診療-診療日-水-午後',
  'business_days.thursday.pm': '診療-診療日-木-午後',
  'business_days.friday.pm': '診療-診療日-金-午後',
  'business_days.saturday.pm': '診療-診療日-土-午後',
  'business_days.sunday.pm': '診療-診療日-日-午後',
  'business_days.public_holiday.pm': '診療-診療日-祝-午後',
  'business_days.memo': '診療-診療日-備考',
}
const businessDaysHalf = {
  'business_days.business_hours.am': 'サービス-営業日-受付',
  'business_days.monday.am': 'サービス-営業日-月',
  'business_days.tuesday.am': 'サービス-営業日-火',
  'business_days.wednesday.am': 'サービス-営業日-水',
  'business_days.thursday.am': 'サービス-営業日-木',
  'business_days.friday.am': 'サービス-営業日-金',
  'business_days.saturday.am': 'サービス-営業日-土',
  'business_days.sunday.am': 'サービス-営業日-日',
  'business_days.public_holiday.am': 'サービス-営業日-祝',
  'business_days.memo': 'サービス-営業日-備考',
}

const hospital = {
  ...basic,
  ...businessDays,
  ...{
    'services.summary': '診療-診療科目',
    'services.memo': '診療-備考'
  }
}
const clinic = {
  ...basic,
  ...businessDays,
  ...{
    'services.summary': '診療-診療科目',
    'services.specialized_service': '診療-専門科目',
    'services.home_visit_care_support': '在宅医療等への対応状況-対応',
    'services.home_visit_care_support_type': '在宅医療等への対応状況-届出状況',
    'services.guidance_at_home_care_management': '居宅療養管理指導',
    'services.home_visit_service': '訪問診療',
    'services.home_call': '外来かかりつけ患者の往診',
    'services.my_doctors_opinion': '主治医意見書',
    'services.visiting_nursing_instruction': '訪問看護指示書',
    'services.drug_handling': '麻薬取扱い',
    'services.memo': '診療-備考'
  }
}
const dentalOffice = {
  ...basic,
  ...businessDays,
  ...{
    'services.summary': '診療-診療科目',
    'services.specialized_service': '診療-専門科目',
    'services.home_visit_care_support': '在宅医療等への対応状況-対応',
    'services.home_visit_care_support_type': '在宅医療等への対応状況-届出状況',
    'services.guidance_at_home_care_management': '居宅療養管理指導',
    'services.home_visit_service': '訪問診療',
    'services.home_visit_oral_care': '訪問口腔ケア',
    'services.implementation_of_feeding_function_therapy': '摂食機能療法実施',
    'services.wheelchair_visit': '車いすでの通院',
    'services.corresponding_insurance': '対応している保険',
    'services.memo': '診療-備考'
  }
}
const pharmacy = {
  ...basic,
  ...businessDaysHalf,
  ...{
    'services.home_visit_care_support': '在宅医療等への対応状況-対応',
    'services.home_visit_care_support_type': '在宅医療等への対応状況-届出状況',
    'services.guidance_at_home_care_management': '居宅療養管理指導',
    'services.service_for_24h': '24時間対応',
    'services.counseling_for_24h': '24時間相談対応',
    'services.one_packaging': '一包化',
    'services.used_needle_recovery': '使用済み注射針回収',
    'services.drug_handling': '麻薬取扱い',
    'services.mobile_reservation': 'スマホ予約',
    'services.delivery': '配達',
    'services.memo': 'サービス-備考'
  }
}
const assignJson = (original) => {
  return { ...basic, ...businessDaysHalf, ...original}
}
const visitingNursingStation = assignJson({
  'services.medical_score': '在宅医療等への対応状況-加算',
  'services.service_for_24h': '24時間対応',
  'services.counseling_for_24h': '24時間相談対応',
  'services.terminal_care': 'ターミナルケア',
  'services.ventilator': '人工呼吸器',
  'services.for_children': '小児対応',
  'services.for_mental_disorder': '精神対応',
  'services.pt_ot_st': 'PT/OT/ST',
  'services.home_care_support': '居宅介護支援併設',
  'services.memo': 'サービス-備考'
})
const homeVisitCareSupportService = assignJson({
  'services.service_for_24h': '24時間対応',
  'services.service_for_24h_memo': '24時間対応-備考',
  'services.medical_score': '特定事業所加算-有無',
  'services.medical_score_class': '特定事業所加算-加算型',
  'services.memo': 'サービス-備考'
})
const visitingCareService = assignJson({
  'services.service_for_24h': '24時間対応',
  'services.service_for_24h_memo': '24時間対応-備考',
  'services.memo': 'サービス-備考'
})
const dayCareSupportService = assignJson({
  'services.capacity': '定員等',
  'services.additional_cost': '保険外での費用',
  'services.bath_assistance': '入浴介助',
  'services.special_bath_assistance': '特殊入浴',
  'services.pt_ot': 'PT/OT配置',
  'services.dementia_prevention_program': '認知症予防プログラム',
  'services.physical_function_improvement': '運動機能向上',
  'services.physical_function_improvement_with_machines': '運動機能向上(マシン)',
  'services.canutrition_improvementpacity': '栄養改善',
  'services.oral_function_improvement': '口腔機能向上',
  'services.gastrostoma': '胃ろう',
  'services.tube_feeding': '経管栄養',
  'services.artificial_anus': '人工肛門',
  'services.balloon_catheter': 'バルンカテーテル',
  'services.insulin_injection': 'インシュリン注射',
  'services.oxygen_inhalation': '在宅酸素',
  'services.tracheotomy': '気管切開',
  'services.suction': '吸引',
  'services.bedsore': '褥そう',
  'services.mrsa': 'MRSA',
  'services.others': 'その他',
  'services.memo': 'サービス-備考'
})

const rehabilitationService = Object.assign({}, dayCareSupportService)

const communityBasedSupportCenter = assignJson({
  'services.service_for_24h': '24時間対応',
  'services.service_for_24h_memo': '24時間対応-備考',
  'services.area': '担当地区',
  'services.memo': 'サービス-備考'
})
const smallMultiHomeCareSupportService = Object.assign({}, homeVisitCareSupportService)

const smallMultiHomeNurseService = assignJson({
  'services.capacity1': '登録定員',
  'services.capacity2': '通いサービスの利用定員',
  'services.capacity3': '宿泊サービスの利用定員',
  'services.service_for_24h': '24時間対応',
  'services.service_for_24h_memo': '24時間対応-備考',
  'services.medical_score': '特定事業所加算-有無',
  'services.medical_score_class': '特定事業所加算-加算型',

  'services.medical_score_satellite': 'サテライト体制',
  'services.medical_score_younger': '若年性認知症利用者受入加算',
  'services.medical_score_emergency': '緊急時訪問介護加算',
  'services.medical_score_special': '特別管理体制',
  'services.medical_score_terminal': 'ターミナルケア体制',
  'services.medical_score_service': 'サービス提供体制強化加算',
  'services.medical_score_general': '総合マネジメント体制強化加算',
  'services.medical_score_nurse': '看護体制強化加算',
  'services.medical_score_visit': '訪問体制強化加算',
  'services.memo': 'サービス-備考'
})
const specialElderlyNursingHome = Object.assign({}, dayCareSupportService)

const geriatricHealthServicesFacility = Object.assign({}, dayCareSupportService)

const hiratsuka = assignJson({
  'services.service_for_24h': '24時間対応',
  'services.service_for_24h_memo': '24時間対応-備考',
  'services.memo': 'サービス-備考'
})
const CsvConfigs = {
  hospital,
  clinic,
  dentalOffice,
  pharmacy,
  visitingNursingStation,
  homeVisitCareSupportService,
  visitingCareService,
  rehabilitationService,
  communityBasedSupportCenter,
  dayCareSupportService,
  smallMultiHomeCareSupportService,
  smallMultiHomeNurseService,
  specialElderlyNursingHome,
  geriatricHealthServicesFacility,
  hiratsuka
}
export { CsvConfigs }

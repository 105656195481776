import googleMapsApi from '@google/maps'

const APIKEY = process.env.VUE_APP_GOOGLE_MAPS_ID

const googleMapsClient = googleMapsApi.createClient({
  key: APIKEY,
  Promise: Promise
})

export default {
  getGeocode (address) {
    return googleMapsClient.geocode({
      address: address,
      language: 'ja'
    }).asPromise()
  },

  getPlaces (name, address, location = {}, radius = 100) {
    return googleMapsClient.places({
      query: name + ' ' + address,
      language: 'ja',
      location: location,
      radius: radius
    }).asPromise()
  }
}

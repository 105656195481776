<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col id="loginbox" cols="12" md="8" sm="12" lg="6" class="mt-5">
        <b-card :header="title" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <h5>利用申請を行いました</h5>
          <p class="p-2">
            <span>申請後、５営業日以内を目安に担当からご連絡します。（年末年始を除く）</span><br>
            <span>また、入力したメールアドレスの確認のため、登録したメールアドレスへメールを行いました。</span><br>
            <span>メールに記載されているURLをクリックしてください。</span><br>
            <span>メールが届かない場合には、しばらく待ってから担当課までご連絡ください。</span>
          </p>
          <router-link to='/login' replace>戻る</router-link>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'userRegistered',
  data () {
    return {
      title: '伊勢原市 在宅医療と介護連携情報WEB'
    }
  }
}
</script>

<style scoped>
#mainbox { margin-top: 50px; }
</style>
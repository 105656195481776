<template>
  <b-navbar toggleable="lg" type="dark" :variant="isAdmin ? 'dark' : 'info'">
    <b-navbar-brand to="/dashboard">
      <span class="small d-sm-none">{{short_logo}}</span>
      <span class="small d-none d-sm-block">{{logo}}</span>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown>
          <template #button-content>
            <span>医療機関/薬局</span>
          </template>
          <div v-for="(item, index) in categoriesDefs.groups.medicals" :key="index">
            <b-dropdown-item :to="'/' + categoriesDefs.items[item].short_model" :active="isActive(item)">
              <span>{{categoriesDefs.items[item].title}}</span><b-badge pill>{{counts(item)}}</b-badge>
            </b-dropdown-item>
            <div class="dropdown-divider" v-if="index === 2"></div>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template #button-content>
            <span>看護/介護事業所</span>
          </template>
          <div v-for="(item, index) in categoriesDefs.groups.nursings" :key="index">
            <b-dropdown-item :to="'/' + categoriesDefs.items[item].short_model" :active="isActive(item)">
              <span>{{categoriesDefs.items[item].title}}</span><b-badge pill>{{counts(item)}}</b-badge>
            </b-dropdown-item>
            <div class="dropdown-divider" v-if="index === 0"></div>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template #button-content>
            <span>ショートステイ/その他</span>
          </template>
          <div v-for="(item, index) in categoriesDefs.groups.others" :key="index">
            <b-dropdown-item :to="'/' + categoriesDefs.items[item].short_model" :active="isActive(item)">
              <span>{{categoriesDefs.items[item].title}}</span><b-badge pill>{{counts(item)}}</b-badge>
            </b-dropdown-item>
            <div class="dropdown-divider" v-if="index === 1"></div>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <span class="small text-white-50 d-none d-sm-block" v-if="isAdmin">管理者としてログインしています
              <i class="fas fa-cog"></i>
            </span>
            <i class="fas fa-cog" v-else></i>
          </template>
          <b-dropdown-item to="/myaccount">アカウント情報</b-dropdown-item>
          <b-dropdown-item to="/logout">ログアウト</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import categoriesDefs from '@/const/CategoriesDefs'

export default {
  name: 'navbarHeader',
  data () {
    return {
      logo: '伊勢原市在宅医療と介護連携WEB',
      short_logo: '伊勢原市在宅医療介護連携',
      categoriesDefs: categoriesDefs
    }
  },
  methods: {
    isActive: function (name) {
      return this.$store.getters.category === name
    },
    counts: function (name) {
      var cat = this.$store.getters.categories[name]
      // console.log('counts=', name, cat, cat && cat.count)
      return cat ? cat.count : 0
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentUserName',
      'currentUserId',
      'isAdmin'
    ])
  }
}
</script>

<style scoped>
span.badge { margin-left: 0.8em; }
</style>

<template>
  <div class="container">
    <div id="loginbox" class="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
      <div class="panel panel-info" v-if="fatalError">
        <div class="panel-body" >
          <div class="alert alert-danger col-sm-12">{{fatalError}}</div>
          <div class="col-sm-12 controls">
            <button class="btn btn-default" @click='onLogin'>戻る</button>
          </div>
        </div>
      </div>

      <div class="panel panel-info" v-else-if="success">
        <div class="panel-body" >
          <div class="alert alert-info col-sm-12">{{ message }}</div>
          <div class="col-sm-12 controls">
            <button class="btn btn-default" @click='onLogin'>ログインへ</button>
          </div>
        </div>
      </div>

      <div class="panel panel-info" v-else-if="mode === 'resetPassword'">
        <div class="panel-heading">
          <div class="panel-title">パスワードの再設定</div>
        </div>
        <div class="panel-body" >
          <div>新しいパスワードを入力してください。</div>
          <div v-show='error' class="alert alert-danger col-sm-12">{{error}}</div>
          <div id="loginform" class="form-horizontal" role="form">
            <div id="login-password" class="input-group" v-bind:class="{ 'has-error': login.isErrorEmail }">
              <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
              <input type="password" class="form-control" v-model="login.newPassword" value="" placeholder="新しいパスワード  8文字以上" maxlength="100">
            </div>
            <div style="margin-top:10px" class="form-group">
              <!-- Button -->
              <div class="col-sm-12 controls">
                <button class="btn btn-success" @click='onChangePassword'>
                <i class="glyphicon glyphicon-saved"></i>
                &nbsp; パスワードを変更</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Firebase from '@/api/firebase'

export default {
  name: 'accountAction',
  data () {
    return {
      error: null,
      fatalError: null,
      mode: null,
      oobCode: null,
      login: {
        newPassword: null,
        isErrorPassword: false
      },
      message: null,
      success: false
    }
  },
  mounted: function () {
    this.mode = this.$route.query['mode']
    this.oobCode = this.$route.query['oobCode']
    this.$log.debug(`mode=${this.mode}`)
    if (!this.validateMode(this.$data.mode)) {
      this.$log.debug('無効な操作', this.$data.mode)
      this.fatalError = '無効な操作です。'
      return
    }
    if (this.mode === 'resetPassword') {
      this.validateCode(this.oobCode)
    } else if (this.mode === 'verifyEmail') {
      this.onApplyActionCode()
    } else {
      this.fatalError = '無効な操作です(E)'
    }
  },
  methods: {
    validateMode: function (mode) {
      return mode === 'resetPassword' || mode === 'verifyEmail'
    },
    validateCode: function (code) {
      if (!code) {
        this.$data.fatalError = '無効なコードです。'
        return
      }
      Firebase.verifyPasswordResetCode(code).then(() => {
        this.$log.debug('verify OK')
      }).catch((err) => {
        this.$log.error(err)
        this.$data.fatalError = '無効なコードです。'
      })
    },
    validatePassword: function (password) {
      return password
    },
    validate: function () {
      this.error = ''
      this.login.isErrorPassword = !this.validatePassword(this.login.newPassword)
      if (this.$data.login.isErrorPassword) {
        this.error = 'パスワードを入力してください。(F)'
      }
      return !this.error
    },
    onApplyActionCode: function () {
      if (!this.oobCode) {
        this.fatalError = '無効なコードです。'
        return false
      }
      this.$log.debug(`oobCode=${this.oobCode}`)
      Firebase.applyActionCode(this.oobCode).then(res => {
        this.$log.debug('verify OK', res)
        this.message = 'メールアドレスを確認しました。利用申請が完了するまでお待ち下さい。完了しましたらメールでお知らせします。'
        this.success = true
      }).catch((error) => {
        this.$log.debug('dispatch onApplyActionCode: Error', error)
        this.error = Firebase._translateMessage(error.code)
        this.fatalError = '無効なコードです。'
      })
    },
    onChangePassword: function () {
      if (!this.validate()) return
      Firebase.changePassword(this.oobCode, this.login.newPassword).then(res => {
        this.$log.debug('dispatch onChangePassword: loginned', res)
        this.$data.message = 'パスワードを変更しました。'
        this.$toastr('success', '', 'パスワードを変更しました。')
        this.$data.success = true
      }).catch((error) => {
        this.$log.debug('dispatch onChangePassword: Error', error)
        this.$data.error = Firebase._translateMessage(error.code)
      })
    },
    onLogin: function () {
      this.$router.replace('/Login')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loginbox, #forgotbox { margin-top: 50px; }
.input-group { margin-bottom: 25px; }
.panel-body { padding-top:30px; }

</style>

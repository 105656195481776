import CategoriesDefs from '@/const/CategoriesDefs'
import _ from 'lodash'

const categoryName = function (category) {
  return CategoriesDefs.items[category] && CategoriesDefs.items[category].name
}

const categoryModel = function (category) {
  return CategoriesDefs.items[category] && CategoriesDefs.items[category].model
}

const categoryTitle = function (category) {
  return CategoriesDefs.items[category] && CategoriesDefs.items[category].title
}

const categoryModelClassName = function (category) {
  return _.upperFirst(categoryModel(category))
}

const categoryShortName = function (category) {
  return CategoriesDefs.items[category] && CategoriesDefs.items[category].short_name
}

const categoryShortModelName = function (category) {
  return CategoriesDefs.items[category] && CategoriesDefs.items[category].short_model
}

const categoryIcon = function (category) {
  return {
    'hospitals': 'fa fa-hospital fa-lg',
    'clinics': 'fa fa-plus-square fa-lg',
    'dentalOffices': 'fa fa-stethoscope fa-lg',
    'pharmacies': 'fa fa-flask fa-lg',
    'visitingNursingStations': 'fa fa-medkit fa-lg',
    'homeVisitCareSupportServices': 'fa fa-home fa-lg',
    'visitingCareServices': 'fa fa-heart fa-lg',
    'rehabilitationServices': 'fa fa-blind fa-lg',
    'dayCareSupportServices': 'fa fa-building fa-lg',
    'communityBasedSupportCenters': 'fa fa-heart fa-lg',
    'smallMultiHomeCareSupportServices': 'fa fa-home fa-lg',
    'smallMultiHomeNurseServices': 'fa fa-home fa-lg',
    'specialElderlyNursingHomes': 'fa fa-wheelchair fa-lg',
    'geriatricHealthServicesFacilities': 'fa fa-wheelchair fa-lg',
    'hiratsukas': 'fa fa-h-square fa-lg'
  }[category]
}

export { categoryIcon, categoryName, categoryModel,
 categoryTitle, categoryModelClassName, categoryShortName, categoryShortModelName }

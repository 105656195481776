<template>
  <b-card no-body>
    <b-card-header class="bg-info text-white">
      <i class="fa fa-building mr-2"></i><span> 基本情報</span>
      <div class="pull-right">
        <BasicInfoEditModalButton @onUpdated="refresh"></BasicInfoEditModalButton>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form horizontal>
        <b-form-group label="名称" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <div>
            <small v-if="item.management_corporation">{{item.management_corporation}}<br></small>
            <h4><strong>{{item.name}}</strong></h4>
          </div>
        </b-form-group>
        <b-form-group :label="director" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <div>{{item.director_name}}</div>
        </b-form-group>
        <b-form-group label="住所" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <span v-if="item.address.zipCode">〒{{item.address.zipCode}}<br></span>
          <span>{{item.address_summary}}</span>
        </b-form-group>
        <b-form-group label="TEL" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <div class="px-2 py-auto">
            <a class="d-sm-none" :href="'tel:' + item.tel">{{item.tel}}</a>
            <span>{{item.tel}}</span>
            <span v-if="item.tel_section">{{item.tel_section}}</span>
            <span v-if="item.tel">(連携優先順位 ({{item.cooperation_priority.tel}}))</span>
          </div>
        </b-form-group>
        <b-form-group label="FAX" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <div class="px-2 py-auto">
            <span>{{item.fax}}</span>
            <span v-if="item.fax_section">{{item.fax_section}}</span>
            <span v-if="item.fax">(連携優先順位 ({{item.cooperation_priority.fax}}))</span>
          </div>
        </b-form-group>
        <b-form-group label="Email" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <span>{{item.email}}</span>
          <span v-if="item.email_section">{{item.email_section}}</span>
        </b-form-group>
        <b-form-group label="Web" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <div v-if="item.url">
            <b-link :href="item.url" target="_blank">{{item.url}}<i class="fas fa-external-link-alt ml-2"></i></b-link>
          </div>
        </b-form-group>
        <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <b-card bg-variant="light" body-class="px-2 py-auto">{{item.memo}}</b-card>
        </b-form-group>
        <b-form-group label="表示順" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3" v-if="isAdmin">
          <span>{{item.view_order}}</span>
        </b-form-group>
      </b-form>
    </b-card-body>
    <b-card-header class="bg-info text-white">
      <i class="fa fa-handshake mr-2"></i><span> 連携窓口</span>
      <div class="pull-right">
        <ContactEditModalButton @onUpdated="refresh"></ContactEditModalButton>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form>
        <b-form-group label="連絡先" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <b-card bg-variant="light" body-class="px-2 py-auto">{{item.contact.name}}</b-card>
        </b-form-group>
        <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <b-card bg-variant="light" body-class="px-2 py-auto">{{item.contact.memo}}</b-card>
        </b-form-group>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicInfoEditModalButton from '@/components/modules/BasicInfoEditModalButton'
import ContactEditModalButton from '@/components/modules/ContactEditModalButton'

export default {
  name: 'basicInfoPanelComp',
  components: {
    BasicInfoEditModalButton,
    ContactEditModalButton
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem',
      'currentUser'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    },
    director: function () {
      return this.$store.getters.categoryDef.director
    }
  },
  methods: {
    refresh () {
      this.$bvToast.toast('施設情報を更新しました。')
    }
  }
}
</script>

<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.form-group { margin-bottom: 5px; }
</style>

<template>
  <div>
    <b-row>
      <b-col class="d-none d-md-block">
        <b-card header="リンク" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <b-list-group>
            <b-list-group-item>
              <b-link href="https://www.rakuraku.or.jp/kaigo" target="_blank">介護情報サービスかながわ <i class="fas fa-external-link-alt"></i></b-link>
              <b-link href="http://www.iryo-kensaku.jp/kanagawa/" target="_blank" class="ml-4">神奈川医療情報検索サービス <i class="fas fa-external-link-alt"></i></b-link>
              <b-link href="http://www.hada-ise-dental.com/" target="_blank" class="ml-4">秦野伊勢原歯科医師会 <i class="fas fa-external-link-alt"></i></b-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col class="d-md-none d-sm-block">
        <b-card header="リンク" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <b-list-group>
            <b-list-group-item>
              <b-link href="https://www.rakuraku.or.jp/kaigo" target="_blank">介護情報サービスかながわ <i class="fas fa-external-link-alt"></i></b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="http://www.iryo-kensaku.jp/kanagawa/" target="_blank">神奈川医療情報検索サービス <i class="fas fa-external-link-alt"></i></b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="http://www.hada-ise-dental.com/" target="_blank">秦野伊勢原歯科医師会 <i class="fas fa-external-link-alt"></i></b-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" lg="4" class="mb-2">
        <b-card
          border-variant="info"
          header="医療機関/薬局"
          header-bg-variant="info"
          header-tag="h5"
          header-class="text-white"
          no-body>
          <ul class="list-group">
            <CategoryPanelComp category="hospitals"></CategoryPanelComp>
            <CategoryPanelComp category="clinics"></CategoryPanelComp>
            <CategoryPanelComp category="dentalOffices"></CategoryPanelComp>
            <CategoryPanelComp category="pharmacies"></CategoryPanelComp>
          </ul>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" class="mb-2">
        <b-card
          border-variant="success"
          header="看護/介護事業所"
          header-bg-variant="success"
          header-tag="h5"
          header-class="text-white"
          no-body>
          <ul class="list-group">
            <CategoryPanelComp category="visitingNursingStations"></CategoryPanelComp>
            <CategoryPanelComp category="homeVisitCareSupportServices"></CategoryPanelComp>
            <CategoryPanelComp category="visitingCareServices"></CategoryPanelComp>
            <CategoryPanelComp category="rehabilitationServices"></CategoryPanelComp>
            <CategoryPanelComp category="dayCareSupportServices"></CategoryPanelComp>
            <CategoryPanelComp category="communityBasedSupportCenters"></CategoryPanelComp>
            <CategoryPanelComp category="smallMultiHomeCareSupportServices"></CategoryPanelComp>
            <CategoryPanelComp category="smallMultiHomeNurseServices"></CategoryPanelComp>
          </ul>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" class="mb-2">
        <b-card
          border-variant="warning"
          header="ショートステイ/その他"
          header-bg-variant="warning"
          header-tag="h5"
          header-class="text-white"
          no-body>
          <ul class="list-group">
            <CategoryPanelComp category="specialElderlyNursingHomes"></CategoryPanelComp>
            <CategoryPanelComp category="geriatricHealthServicesFacilities"></CategoryPanelComp>
            <CategoryPanelComp category="hiratsukas"></CategoryPanelComp>
          </ul>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { categoryIcon } from '@/utils/CategoryUtils'
import CategoryPanelComp from '@/components/modules/CategoryPanelComp'

export default {
  name: 'dashboardPanel',
  data () {
    return {}
  },
  components: {
    CategoryPanelComp
  },
  methods: {
    iconClass: function (name) {
      return categoryIcon(name)
    },
    loadCategories () {
      this.$log.debug('loadCategories')
      this.$store.dispatch('fetchCategories')
        .then(cats => {
          this.$log.debug('DashboardPanel::loadCategories:cats=', cats)
        }).catch((error) => {
          this.$log.error(error)
        })
    }
  },
  created: function () {
    this.loadCategories()
  }
}
</script>

<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.margin20 { margin-right: 20px; }
</style>

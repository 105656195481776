import 'object.values'
import 'es6-promise'
import 'whatwg-fetch'

import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

import VueGtag from "vue-gtag"

// Add
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-honoka'

// import 'firebase/app'
import Firebase from '@/api/firebase'
import '@firebase/polyfill'

// import 'datatables.net/js/jquery.dataTables.js'
import 'font-awesome/css/font-awesome.min.css'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as VueGoogleMaps from 'vue2-google-maps'

import ToggleButton from 'vue-js-toggle-button'

// import VueToastr from '@deveodk/vue-toastr'
// import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'

import VueLogger from 'vuejs-logger';

// for debugging
if (process.env.VUE_APP_DEBUG !== 'false') Vue.config.debug = true
Vue.config.productionTip = false

const options = {
  isEnabled: true,
  logLevel : (process.env.VUE_APP_DEBUG !== 'false') ? 'debug' : 'error',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, options)

Vue.use(Vuex)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
dom.watch()

sync(store, router)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYSTICS_ID }
}, router)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_ID,
    libraries: 'places',
    lang: 'ja'
  }
})

Vue.use(ToggleButton)
/*
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 3000
})
*/
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

global._App = app
Firebase.initFirebase(app)

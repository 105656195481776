import { render, staticRenderFns } from "./VisitingCareServiceDetail.vue?vue&type=template&id=2d156344&scoped=true&"
import script from "./VisitingCareServiceDetail.vue?vue&type=script&lang=js&"
export * from "./VisitingCareServiceDetail.vue?vue&type=script&lang=js&"
import style0 from "./VisitingCareServiceDetail.vue?vue&type=style&index=0&id=2d156344&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d156344",
  null
  
)

export default component.exports
<template>
  <div>
    <NavbarHeaderComp></NavbarHeaderComp>
    <div class="container">
      <div class="page-header" id="banner">
        <BreadCrumbList :screen="screen"></BreadCrumbList>
        <Buttons></Buttons>
      </div>
      <DetailComp></DetailComp>
      <FooterComp></FooterComp>
    </div>
  </div>
</template>

<script>
import NavbarHeaderComp from '@/components/NavbarHeader'
import BreadCrumbList from '@/components/modules/BreadCrumbList'
import Buttons from '@/components/modules/OpenButtons'
import DetailComp from '@/components/DayCareSupportServiceDetail'
import FooterComp from '@/components/modules/FooterComp'

export default {
  name: 'rehabilitationServiceView',
  data () {
    return {
      category: 'rehabilitationServices',
      screen: '詳細',
      id: this.$route.params.id
    }
  },
  components: {
    NavbarHeaderComp,
    BreadCrumbList,
    Buttons,
    DetailComp,
    FooterComp
  },
  created: function () {
    this.$store.dispatch('changeCategory', this.$data.category)
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>

</style>
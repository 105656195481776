<template>
  <div>
    <b-row>
      <b-col md="6">
        <BasicInfoPanel></BasicInfoPanel>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="bg-success text-white">
            <i class="fas fa-heart"></i><span> サービス</span>
            <div class="pull-right">
              <ServiceEditModalButton @onUpdated="refresh"></ServiceEditModalButton>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form horizontal>
              <b-form-group label="営業日" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <BusinessDaysTable :half="true"></BusinessDaysTable>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light" body-class="px-2 py-auto">{{item.business_days.memo}}</b-card>
              </b-form-group>
            </b-form>
            <hr>
            <b-form horizontal>
              <b-form-group label="24時間連絡対応" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <table class="table table-bordered table-striped table-sm">
                  <thead>
                    <tr class="small">
                      <th>対応</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center"><ServiceView :value="item.services.service_for_24h"></ServiceView></td>
                      <td>
                        {{item.services.service_for_24h_memo}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="help-block small">
                  ※ ◯：対応可能、△：応相談、☓：対応不可<br>
                  ※ 24時間の連絡の取れる体制の有無
                </p>
              </b-form-group>
              <b-form-group label="担当地区" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light" body-class="px-2 py-auto">{{item.services.area}}</b-card>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light" body-class="px-2 py-auto">{{item.services.memo}}</b-card>
                <p class="small mt-2">※連絡する際の要望、注意点、病院の特定など、ケアマネージャーに伝えたいことなど</p>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col lg="6">
        <googleMapComp></googleMapComp>
      </b-col>
      <b-col lg="6">
        <b-card header="更新履歴" header-class="bg-info text-white">
          <b-list-group>
            <b-list-group-item class="small">{{formatDate(item.created_at)}} 初期登録</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import BasicInfoPanel from '@/components/modules/BasicInfoPanelComp'
import BusinessDaysTable from '@/components/modules/BusinessDaysTableComp'
import ServiceEditModalButton from '@/components/modules/CommunityBasedSupportCenterServiceEditModalButton'
import ServiceView from '@/components/modules/ServiceViewComp'
import googleMapComp from '@/components/modules/GoogleMapComp'

export default {
  name: 'communityBasedSupportCenterDetail',
  components: {
    BasicInfoPanel,
    BusinessDaysTable,
    ServiceEditModalButton,
    ServiceView,
    googleMapComp
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    }
  },
  methods: {
    formatDate (value, fmt = 'YYYY/MM/DD') {
      return (value == null) ? '--' : moment(value, 'YYYY-MM-DD').format(fmt)
    },
    refresh () {
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.form-group { margin-bottom: 5px; }
</style>

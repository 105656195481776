import Vue from 'vue'
import Vuex from 'vuex'
import apis from '@/api/apis'
import Firebase from '@/api/firebase'
import CategoriesDefs from '@/const/CategoriesDefs.js'
import Facility from '@/store/Facility'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'prod'

const state = {
  category: '',
  loggedIn: false,
  token: null,
  currentUser: {
    uid: '',
    name: '',
    email: '',
    emailVerified: '',
    providerId: '',
    company: '',
    isAdmin: false,
    created_at: null,
    updated_at: null,
    loggedIn: false,
    verified: false
  },

  categories: {},

  hospitals: {},
  clinics: {},
  dentalOffices: {},
  pharmacies: {},
  visitingNursingStations: {},
  homeVisitCareSupportServices: {},
  visitingCareServices: {},
  rehabilitationServices: {},
  dayCareSupportServices: {},
  communityBasedSupportCenters: {},
  smallMultiHomeCareSupportServices: {},
  smallMultiHomeNurseServices: {},
  specialElderlyNursingHomes: {},
  geriatricHealthServicesFacilities: {},
  hiratsukas: {}
  // TODO etc...
}

const mutations = {
  setCategory (state, { category }) {
    state.category = category
  },

  setCategories (state, { categories }) {
    state.categories = categories
  },
  updateCategoryCounts (state, { category, count }) {
    state[category].count = count
  },

  onAuthStateChanged (state, { user }) {
    debug && Vue.$log.debug('mutations:onAuthStateChanged', user)
    state.currentUser = user
    state.loggedIn = user.uid !== ''
  },

  setUser (state, { key, val }) {
    Vue.set(state.currentUser, key, val) // 属性を追加する
  },

  setItem (state, { category, key, item }) {
    debug && Vue.$log.debug('store:setItem', category, key, item)
    Vue.set(state[category], key, item)
  },

  setItems (state, { category, items }) {
    debug && Vue.$log.debug('store.setItems', category, items)
    state[category] = items || {}
  },

  removeItem (state, { category, key }) {
    delete state[category][key]
  },

  setCurrentUserToken (state, { token }) {
    state.token = token
  }
}

const actions = {
  changeCategory ({ commit }, category) {
    debug && Vue.$log.debug('store:changeCategory', category)
    commit('setCategory', { category })
  },

  onAuthStateChanged ({ commit }, user) {
    debug && Vue.$log.debug('action:onAuthStateChanged', user)
    commit('onAuthStateChanged', { user })
  },

  createUserWithEmailAndPassword ({ commit }, { email, password, name, company }) {
    debug && Vue.$log.debug('action:createUserWithEmailAndPassword', commit)
    debug && Vue.$log.debug('createUserWithEmailAndPassword', email, password)
    return Firebase.createUserWithEmailAndPassword(email, password, name, company)
  },

  signInWithEmailAndPassword ({ commit }, { email, password }) {
    debug && Vue.$log.debug('store::signInWithEmailAndPassword', email, password)
    return Firebase.signInWithEmailAndPassword(email, password).then(res => {
      debug && Vue.$log.debug('store::signInWithEmailAndPassword.loginned.', res)
      return Firebase.getAuth().getIdToken().then(res => {
        debug && Vue.$log.debug('store::signInWithEmailAndPassword.getIdToken.', res)
        return res
      })
    }).then(token => {
      debug && Vue.$log.debug('store::signInWithEmailAndPassword.token', token)
      return apis.getCustomToken(token)
    }).then(customToken => {
      debug && Vue.$log.debug('store::signInWithEmailAndPassword.customToken', customToken)
      return Firebase.signInWithCustomToken(customToken)
    }).then(() => {
      Firebase.getAuth().getIdToken().then(token => {
        debug && Vue.$log.debug('CurrentUserToken changed', token)
        apis.setToken(token)
        commit('setCurrentUserToken', { token: token })
      })
    })
  },

  signInWithGoogle () {
    return Firebase.signInWithGoogle()
  },

  signOut () {
    return Firebase.signOut()
  },

  setUserInfo ({ commit, state }, { key, val }) {
    debug && Vue.$log.debug('setUserInfo:', key, val)
    return new Promise((resolve, reject) => {
      if (state.loggedIn) { // is signed in. Firebase
        Firebase.setUserInfo(key, val)
          .then(() => {
            debug && Vue.$log.debug('commit setUser')
            commit('setUser', { key, val })
            resolve()
          }).catch(reject)
      } else { // is signed out. Localstrage
        reject('still dev for guest')
      }
    })
  },

  fetchCategories ({ commit }) {
    return Firebase.fetchCategories()
      .then(categories => {
        debug && Vue.$log.debug('store::fetchCategories', categories)
        commit('setCategories', { categories })
      }).catch(err => {
        debug && Vue.$log.debug('store::fetchCategories.err', err)
      })
  },

  fetchItem ({ commit, state }, { category }) {
    return Firebase.fetchItem(category, state.route.params.id)
      .then(obj => {
        debug && Vue.$log.debug('store::fetchItem', obj)
        commit('setItem', {
          category: category,
          key: obj.key,
          item: obj.item
        })
      })
  },

  fetchItems ({ commit }, { category, count }) {
    return new Promise((resolve, reject) => {
      Firebase.fetchItems(category, count).then((items) => {
        debug && Vue.$log.debug('store::fetchItems', category, items)
        commit('setItems', {
          category: category,
          items: items
        })
        resolve(items)
      }).catch(reject)
    })
  },

  addItem ({ commit, state }, { category, name }) {
    return new Promise((resolve, reject) => {
      if (state.loggedIn) { // is signed in. Firebase
        debug && Vue.$log.debug('store::addItem: ', category, name)
        var newItem = Facility.newItemFromSkelton({
          category_id: category,
          category: { id: category, name: CategoriesDefs.items[category].title },
          name: name
        })
        debug && Vue.$log.info('store::addItem:newItemfromSkelton', category, newItem)

        if (state[category][newItem.id]) {
          debug && Vue.$log.debug('store::addItem:check NG', category, name)
          reject('同じ名前の施設があります。')
        }
        debug && Vue.$log.info('store::addItem:check ok', category, name)

        Firebase.setItem(category, newItem.id, newItem)
          .then((obj) => {
            debug && Vue.$log.info('store::addItem::setItem.commit', obj)
            commit('setItem', {
              category: obj.item.category_id,
              key: obj.key,
              item: obj.item
            })
            resolve(obj.key)
          }).catch(err => {
            reject(err)
          })
      } else { // is signed out. Localstrage
        reject('still dev for guest')
      }
    })
  },

  deleteItem ({ commit, state }, { category, id }) {
    return new Promise((resolve, reject) => {
      if (state.loggedIn) { // is signed in. Firebase
        Firebase.deleteItem(category, id)
          .then(key => {
            commit('removeItem', { category: category, key: key })
            resolve(key)
          }).catch(reject)
      } else { // is signed out. Localstrage
        reject('still dev for guest')
      }
    })
  },

  updateItem ({ commit, state }, { item }) {
    return new Promise((resolve, reject) => {
      if (state.loggedIn) { // is signed in. Firebase
        const key = state.route.params.id
        debug && Vue.$log.debug('store::updateItem', item)
        Firebase.setItem(item.category_id, key, item)
          .then(obj => {
            debug && Vue.$log.debug('setItem:returns=', obj.item)
            debug && Vue.$log.debug('setItem:returns=', obj.item.category_id)
            debug && Vue.$log.debug('setItem:returns=', obj.key)

            commit('setItem', {
              category: obj.item.category_id,
              key: key,
              item: obj.item
            })
            resolve(obj.item)
          }).catch(reject)
      } else { // is signed out. Localstrage
        reject('still dev for guest')
      }
    })
  }
}

const getters = {
  category: state => state.category,
  categoryDef: state => {
    return CategoriesDefs.items[state.category] || {}
  },
  categories: state => state.categories,
  currentUser: state => state.currentUser,
  currentUserName: state => state.currentUser.name,
  currentUserId: state => state.currentUser.uid,
  isAdmin: state => state.currentUser.isAdmin, // TODO

  counts: state => state.counts,

  hospitals: state => state.hospitals,
  clinics: state => state.clinics,
  dentalOffices: state => state.dentalOffices,
  pharmacies: state => state.pharmacies,
  visitingNursingStations: state => state.visitingNursingStations,
  homeVisitCareSupportServices: state => state.homeVisitCareSupportServices,
  visitingCareServices: state => state.visitingCareServices,
  rehabilitationServices: state => state.rehabilitationServices,
  dayCareSupportServices: state => state.dayCareSupportServices,
  communityBasedSupportCenters: state => state.communityBasedSupportCenters,
  smallMultiHomeCareSupportServices: state => state.smallMultiHomeCareSupportServices,
  smallMultiHomeNurseServices: state => state.smallMultiHomeNurseServices,
  specialElderlyNursingHomes: state => state.specialElderlyNursingHomes,
  geriatricHealthServicesFacilities: state => state.geriatricHealthServicesFacilities,
  hiratsukas: state => state.hiratsukas,

  currentItemId: ({ route }) => route.params.id,
  currentItem: state => {
    debug && Vue.$log.debug('currentItem=', state.category, state.route.params.id, state[state.category])
    return state.route.params.id && state.category
      ? state[state.category][state.route.params.id]
      : {}
  },
  currentItems: state => {
    return state.category ? state[state.category] : {}
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
//  Head,
  strict: debug
})

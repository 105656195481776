<template>
  <div id='map-canvas'>
    <gmap-map
      :center="_center"
      :zoom="zoom"
      style="width: 100%; height: 600px"
    >
      <gmap-info-window 
        :options="infoWindowParams.options" 
        :position="infoWindowParams.position" 
        :opened="infoWindowParams.opened"
        :place="{ placeId: infoWindowParams.item.placeId }"
        :zIndex="1000"
        @closeclick="infoWindowParams.opened=false">
        <h5><strong>{{infoWindowParams.item.title}}</strong></h5>
        <div>{{infoWindowParams.item.address}}</div>
        <div>TEL: {{infoWindowParams.item.tel}}</div>
        <router-link :to="toViewPath(infoWindowParams.item)">詳細</router-link>
        | <a :href="toGoogleMapsUrl" target="_blank">Googleマップで見る</a>
      </gmap-info-window>
      <gmap-marker 
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :title="m.title"
        :clickable="true"
        @click="onMarkerEvent($event, m)"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import _ from 'lodash'
import { googleMapsUrl } from '@/utils/GoogleMapsUtils'
import { categoryShortModelName } from '@/utils/CategoryUtils'

export default {
  name: 'googleMapComp',
  props: {
    'defaultZoom': {
      default: 13,
      type: Number
    }
  },
  data () {
    return {
      items: {},
      center: {},
      markers: {},
      zoom: this.defaultZoom,
      infoWindowParams: {
        options: {
          pixelOffset: {
            width: 0,
            height: -35
          },
          zIndex: 1000
        },
        position: { lat: 35.4036602, lng: 139.3148285 },
        opened: false,
        item: {
          id: '',
          title: '',
          address: '',
          tel: '',
          category: '',
          placeId: ''
        }
      }
    }
  },
  computed: {
    _center: function () {
      return {
        lat: 35.4036602,
        lng: 139.3148285
      }
    },
    toGoogleMapsUrl: function () {
      return googleMapsUrl({
        title: this.infoWindowParams.item.title,
        placeId: this.infoWindowParams.item.placeId,
        position: this.infoWindowParams.position
      })
    }
  },

  methods: {
    toViewPath (infoWindowParams) {
      this.$log.debug('toViewPath', infoWindowParams)
      if (!infoWindowParams.category || !infoWindowParams.id) {
        return '/dashboard'
      }
      return '/' + categoryShortModelName(infoWindowParams.category) + '/' + infoWindowParams.id
    },
    loadItems () {
      this.$store.dispatch('fetchItems', { category: this.$store.getters.category }).then((items) => {
        this.$data['items'] = items
        this.$emit('GET_AJAX_COMPLETE')
      }).catch((error) => {
        this.$log.error(error)
      })
    },
    getData () {
      this.$log.debug('getData=', this.items)
      this.items = this.$store.state[this.$store.getters.category]
      return this.items
    },

    toMarker: function (item) {
      return item && item.address && item.address.latitude ? {
        id: item.id,
        position: {
          lat: parseFloat(item.address.latitude),
          lng: parseFloat(item.address.longitude)
        },
        title: item.name,
        category: item.category_id,
        address: item.address_summary,
        tel: item.tel,
        placeId: item.address.placeId || ''
      }
      : {}
    },

    loadMarker: function () {
      var items = this.getData()
      this.$log.debug('items', items)
      var that = this
      return _.values(items).map(function (m) {
        return that.toMarker(m)
      })
    },

    onMarkerEvent: function (event, item) {
      if (!item) return
      var _clicked = item
      Object.assign(this.infoWindowParams.item, _clicked)
      this.infoWindowParams.position = _clicked.position
      this.infoWindowParams.opened = true
      this.$log.debug('infoWindowParams', this.infoWindowParams)
    }
  },
  created: function () {
    this.$log.debug('created', this.defaultZoom)
    this.loadItems()
    this.$on('GET_AJAX_COMPLETE', () => {
      // var dt = this.getData('items')
      // console.log('dt=', dt)
      this.markers = this.loadMarker()
    })
  }
}
</script>

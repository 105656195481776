// import __firebase from 'firebase'
import __firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/database'

import moment from 'moment'

const debug = process.env.NODE_ENV !== 'production'

// TODO 外部へ
export const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: 'isehara-comedicare.firebaseapp.com',
  databaseURL: 'https://isehara-comedicare.firebaseio.com',
  projectId: 'isehara-comedicare',
  storageBucket: 'isehara-comedicare.appspot.com',
  messagingSenderId: '232980306205'
}
__firebase.initializeApp(firebaseConfig)

// Create DatabaseAPI
function createDatabase ({ version }) {
  return __firebase.database().ref(version)
}

const database = createDatabase({
  version: '/v0',
  config: {
    databaseURL: 'https://isehara-comedicare.firebaseio.com'
  }
})

var auth = __firebase.auth()

// variable
var _userInfo = {}
var _userRef = null

var _items = {
  'hospitals': {},
  'clinics': {},
  'dentalOffices': {},
  'pharmacies': {},
  'visitingNursingStations': {},
  'homeVisitCareSupportServices': {},
  'visitingCareServices': {},
  'rehabilitationServices': {},
  'dayCareSupportServices': {},
  'communityBasedSupportCenters': {},
  'smallMultiHomeCareSupportServices': {},
  'smallMultiHomeNurseServices': {},
  'specialElderlyNursingHomes': {},
  'geriatricHealthServicesFacilities': {},
  'hiratsukas': {}
}
var _itemsRef = null

var _categories = {}
var _categoriesRef = null

const MAX_COUNTS = 500

const ERROR_MSGS = {
  'auth/email-already-in-use': 'すでに登録されているメールアドレスです',
  'auth/operation-not-allowed': '登録できないメールアドレスです',
  'auth/weak-password': 'パスワードは６文字以上で入力してください',
  'auth/wrong-password': 'メールアドレスまたはパスワードが違います',
  'auth/user-disabled': '無効なアカウントです',
  'auth/user-not-found': 'メールアドレスまたはパスワードが違います',
  'auth/invalid-email': 'メールアドレスを正しく入力してください',
  'auth/invalid-password': 'メールアドレスまたはパスワードが違います',
  'auth/argument-error': '引数エラー'
}

var _App = {}

export default {

  // Sets up shortcuts to Firebase features and initiate firebase auth.
  initFirebase (app) {
    _App = app
    // Initiates Firebase auth and listen to auth state changes.
    auth.onAuthStateChanged(this.onAuthStateChanged.bind(this))
  },

  getAuth () {
    return auth.currentUser || {}
  },

  createUserWithEmailAndPassword (email, password, name, company) {
    return auth.createUserWithEmailAndPassword(email, password).then(() => {
      var user = auth.currentUser
      debug && _App.$log.debug('created user!', user)
      user.updateProfile({ displayName: name }).then(function () {
        debug && _App.$log.debug('sendEmailVerification')
        user && user.sendEmailVerification()
        var now = parseInt(moment().format('x'))

        database.child('tempUsers').child(user.uid).set(
          {
            uid: user.uid,
            name: name,
            company: company,
            email: user.email,
            emailVerified: user.emailVerified,
            providerId: user.providerId,
            created_at: now,
            updated_at: now
          })
      }, function (error) {
        debug && _App.$log.error(error)
        throw error
      })
    })
  },

  // Signs-in
  signInWithEmailAndPassword (email, password) {
    // Sign in Firebase using popup auth and Google as the identity provider.
    return auth.signInWithEmailAndPassword(email, password)
  },
  signInWithCustomToken (token) {
    return auth.signInWithCustomToken(token)
  },

  signInWithGoogle () {
    // Sign in Firebase using popup auth and Google as the identity provider.
    var provider = new __firebase.auth.GoogleAuthProvider()
    return auth.signInWithPopup(provider)
  },

  // Signs-out
  signOut () {
    // Sign out of Firebase.
    auth.signOut()
  },

  // Triggers when the auth state change for instance when the user signs-in or signs-out.
  onAuthStateChanged (user) {
    debug && _App.$log.debug('onAuthStateChanged user=', user)
    if (user) { // User is signed in!
      debug && _App.$log.debug('onAuthStateChanged signin=', user)
      this.fetchUserInfo(user.uid)
        .then(val => {
          debug && _App.$log.debug('dispatch: onAuthStateChanged', val)
          _userInfo = val
          _userInfo.verified = true
          auth.currentUser.getIdToken(true).then(idToken => {
            debug && _App.$log.debug('firebase::getIdToken()', idToken)
            _userInfo.token = idToken
            _App.$store.dispatch('onAuthStateChanged', Object.assign({}, _userInfo))
          }).catch(error => {
            debug && _App.$log.error(error)
          })
        }).catch(() => {
          _userInfo = {
            loggedIn: true,
            uid: user.uid,
            name: user.name,
            emailVerified: user.emailVerified,
            providerId: user.providerId,
            verified: false
          }
          _App.$store.dispatch('onAuthStateChanged', Object.assign({}, _userInfo))
        })
    } else {
      _userInfo = {
        loggedIn: false,
        uid: '',
        name: 'guest',
        verified: false
      }
      _App.$store.dispatch('onAuthStateChanged', Object.assign({}, _userInfo))
    }
  },

  fetchUserInfo (uid) {
    return new Promise((resolve, reject) => {
      _userRef = database.child('users')
      _userRef.child(uid).once('value')
        .then(snapshot => {
          const user = snapshot.val()
          debug && _App.$log.debug('fetchUserInfo: ', user)
          resolve(user)
        }).catch(reject)
    })
  },

  setUserInfo (key, val) {
    return new Promise((resolve, reject) => {
      if (key && val && this.checkSignedInWithMessage()) {
        debug && _App.$log.debug('setUserInfo', _userInfo)
        _userRef = database.child('users').child(_userInfo.uid).child(key)
        _userRef.set(val)
          .then(() => {
            _userInfo[key] = val
            resolve()
          }).catch(reject)
      }
    })
  },

  checkSignedInWithMessage () {
    if (auth.currentUser) return true
    return false
  },

  sendPasswordResetEmail (email) {
    return auth.sendPasswordResetEmail(email)
  },

  fetchCategories () {
    return new Promise((resolve, reject) => {
      _categoriesRef = database.child('categories')
      _categoriesRef.once('value')
        .then(snapshot => {
          const cats = snapshot.val()
          _categories = cats
          debug && _App.$log.debug('firebase::fetchUserInfo: ', _categories)
          resolve(_categories)
        }).catch(reject)
    })
  },

  fetchItem (category, key) {
    return new Promise((resolve, reject) => {
      _itemsRef = database.child('/facilities/' + category).child(key)
      _itemsRef.once('value')
        .then(snapshot => {
          const item = snapshot.val()
          _items[category][key] = item
          resolve({
            item: Object.assign({}, item),
            key: key
          })
        }).catch(reject)
    })
  },

  fetchItems (category, count) {
    debug && _App.$log.debug('firebase.fetchItems=', category, count)
    return new Promise((resolve, reject) => {
      debug && _App.$log.debug('fetchItems:category', category)
      _itemsRef = database.child('/facilities/' + category)
      _itemsRef.orderByChild('name').limitToLast(count || MAX_COUNTS).once('value')
        .then(snapshot => {
          const item = snapshot.val() || {}
          Object.keys(item).forEach(key => {
            _items[category][key] = item[key]
          })
          debug && _App.$log.debug('firebase:fetchItems', category, _items[category])
          resolve(Object.assign({}, _items[category]))
        }).catch(reject)
    })
  },

  setItem (category, key, item) {
    debug && _App.$log.debug('firebase::setItem', category, key, item)
    return new Promise((resolve, reject) => {
      // Check that the user entered a message and is signed in.
      if (item && this.checkSignedInWithMessage()) {
        var categoryPath = category // TODO ...
        debug && _App.$log.debug('firebase::setItem', categoryPath, item.id)
        _itemsRef = database.child('/facilities/' + categoryPath).child(item.id)
        var key = item.id
        const now = parseInt(moment().format('x'))
        Object.assign(item, {
          updated_at: now,
          author: {
            uid: _userInfo.uid,
            name: _userInfo.name
          }
        })
        _itemsRef.set(item).then(() => {
          _items[key] = item
          resolve({
            item: item,
            key: key
          })
        }).catch(reject)
      }
    })
  },

  deleteItem (category, key) {
    return new Promise((resolve, reject) => {
      if (key && this.checkSignedInWithMessage()) {
        _itemsRef = database.child('/facilities/' + category).child(key)
        _itemsRef.remove()
          .then(() => {
            resolve(key)
            delete _items[key]
          }).catch(reject)
      }
    })
  },
  verifyPasswordResetCode (actionCode) {
    return new Promise((resolve, reject) => {
      auth.verifyPasswordResetCode(actionCode).then(() => {
        resolve()
      }).catch(reject)
    })
  },
  changePassword (actionCode, newPassword) {
    return Promise.resolve().then(() => {
      auth.verifyPasswordResetCode(actionCode)
    }).then(() => {
      auth.confirmPasswordReset(actionCode, newPassword)
    })
  },
  applyActionCode (actionCode) {
    return auth.applyActionCode(actionCode)
  },
  _database () {
    return database
  },

  _translateMessage (code) {
    return code && ERROR_MSGS[code]
  }

}

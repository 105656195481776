<template>
  <div>
    <b-row>
      <b-col>
        <CsvDownloadButton csvType="hospital" :list="items"></CsvDownloadButton>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="8" class="my-1">
        <b-btn variant="outline-secondary" @click="refresh"><i class="fas fa-sync-alt"></i></b-btn>
      </b-col>
      <b-col lg="4" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="table.filter"
              type="search"
              placeholder="検索"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!table.filter" @click="table.filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-table
        :fields="table.fields"
        :items="items"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :busy="loading"
        :filter="table.filter"
        @row-clicked="goDetail"
        thead-class="bg-info text-white"
        head-variant="info"
        responsive="sm"
        striped bordered hover fixed
        >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner><span>読込中</span>
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <strong>{{ data.value }}</strong>
          <span v-if="isAdmin">
            <span v-if="data.item.opened === 'true' || data.item.opened === true">
              <b-badge variant="success" class="ml-1"><i class="fas fa-check mr-1"></i>公開</b-badge>
            </span>
            <span v-else>
              <b-badge variant="secondary" class="ml-1"><i class="fas fa-times mr-1"></i>非公開</b-badge>
            </span>
          </span>
        </template>
        <template v-slot:cell(tel))="data">
          <a :href="'tel:' + data.value" class="d-sm-none">{{ data.value }}</a>
          <span class="d-none d-sm-block">{{ data.value }}</span>
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { categoryName } from '@/utils/CategoryUtils'
import CsvDownloadButton from '@/components/modules/CsvDownloadButton'

export default {
  name: 'hospitalList',
  components: {
    CsvDownloadButton
  },
  data () {
    return {
      loading: false,
      items: [],
      table: {
        fields: [
          { key: 'name', label: '名称', sortable: true, thClass: 'w-auto' },
          { key: 'address.line2', label: '住所', sortable: true, thClass: 'w-15' },
          { key: 'tel', label: 'TEL', sortable: true, thClass: 'w-15' },
          { key: 'services.summary', label: '診療科', sortable: true, thClass: 'w-15' },
          { key: 'business_days.summary', label: '診療日', sortable: true, thClass: 'w-15' }
        ],
        sortBy: 'view_order',
        sortDesc: false,
        filter: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'category',
      'currentUser',
      'currentItems'
    ]),
    orderedItems () {
      if (this.isAdmin) return this.currentItems
      return _.chain(this.currentItems).filter((item) => {
        if (item.opened === true) return true
        return (this.currentUser.permits && this.currentUser.permits[item.opened])
      }).value()
    }
  },
  mounted () {
    this.loadItems()
  },
  methods: {
    loadItems () {
      this.loading = true
      if (!_.isEmpty(this.orderedItems)) {
        this.$log.debug('fetchItem Skipped!')
        // this.drawTable(this.orderedItems)
        this.items = Object.values(this.orderedItems)
        this.$log.debug('loadItems=', this.items)
        this.loading = false
        return
      }
      this.$store.dispatch('fetchItems', { category: this.category }).then(() => {
        // this.$emit('GET_AJAX_COMPLETE')
        this.items = Object.values(this.orderedItems).sort((a, b) => a.name < b.name ? 1 : -1)
        this.loading = false
      }).catch((error) => {
        this.$log.error(error)
        this.loading = false
      })
    },
    refresh () {
      this.loading = true
      this.$store.dispatch('fetchItems', { category: this.category }).then(() => {
        this.items = Object.values(this.orderedItems).sort((a, b) => a.name < b.name ? 1 : -1)
        setTimeout(function () { this.loading = false }.bind(this), 200)
      })
    },
    goDetail (data) {
      const _name = _.upperFirst(categoryName(this.category))
      this.$router.push({name: _name, params: { id: data.id }})
    }
  }
}
</script>

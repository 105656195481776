<template>
  <div>
    <NavbarHeaderComp></NavbarHeaderComp>
    <b-container>
      <b-row class="mt-2 mb-3">
        <b-col class="border-bottom">
          <h6 class="hello">
            {{currentUserName}} 様、{{greeting}}
          </h6>
        </b-col>
      </b-row>
      <DashboardPanel></DashboardPanel>
      <FooterComp></FooterComp>
    </b-container>
  </div>
</template>

<script>
import NavbarHeaderComp from '@/components/NavbarHeader'
import DashboardPanel from '@/components/DashboardPanel'
import FooterComp from '@/components/modules/FooterComp'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'dashboard',
  data () {
    return {
    }
  },
  components: {
    NavbarHeaderComp,
    DashboardPanel,
    FooterComp
  },
  computed: {
    ...mapGetters([
      'currentUserName'
    ]),
    greeting: function () {
      var hrs = moment().hours()
      var dd = (hrs < 5 || hrs > 17) ? 2 : (hrs < 10) ? 0 : 1
      return ['おはようございます', 'こんにちは', 'こんばんは'][dd]
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
h4.hello { padding-top: 20px; }
</style>
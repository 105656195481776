<template>
  <b-form-select ref="selector" class="p-2" :value="value" @change="updateValue" :options="options">
  </b-form-select>
</template>

<script>
export default {
  name: 'businessDaySelector',
  props: ['value'],
  data () {
    return {
      options: [
        { text: '◯', value: 2 },
        { text: '☆', value: 1 },
        { text: '-', value: 0 }
      ]
    }
  },
  methods: {
    updateValue: function (v) {
      this.$log.debug(v)
      this.$emit('input', v)
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div v-if="isAdmin">
    <div class="d-inline-block w-100 border-bottom p-1">
      <b-btn class="float-right" variant="success" size="sm" @click="openRegisterModal">
        <i class="fas fa-plus"></i>
        新規登録
      </b-btn>
    </div>
    <b-modal ref="editModal" size="lg"
      header-bg-variant="success"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title">
          <i :class="iconClass"></i> {{title}}-新規登録
        </h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <b-form-group label-for="newItemName" label="名称" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
          <b-form-input id="newItemName" type="text" class="form-control" v-model="newItemName" placeholder="100文字以内"></b-form-input>
        </b-form-group>
        <p class="help-block">※ 他と重複しない名前としてください。</p>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="danger" class="mr-2" @click="onRegister">保存</b-btn>
          <b-btn variant="secondary" @click="$refs.editModal.hide">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { categoryTitle, categoryIcon } from '@/utils/CategoryUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'registerModalButton',
  props: {
    onRegistered: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      showModal: true,
      newItemName: null,
      error: null,
      isErrorName: false
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'category'
    ]),
    title () {
      return categoryTitle(this.category)
    },
    iconClass () {
      return categoryIcon(this.category)
    }
  },
  mounted () {
    this.loadItem()
  },
  methods: {
    loadItem () {
      this.error = null
      this.newItemName = null
      this.isErrorName = false
    },
    openRegisterModal () {
      this.loadItem()
      this.$refs['editModal'].show()
    },
    onRegister () {
      if (!this.$data.newItemName) {
        this.error = '名称を入力してください。'
        this.isErrorName = true
        return
      }
      this.error = null
      this.$store.dispatch('addItem', {
        category: this.category,
        name: this.$data.newItemName
      }).then(() => {
        this.$log.debug('registered!')
        this.onRegistered && this.onRegistered()
        this.$refs['editModal'].hide()
      }).catch((error) => {
        this.$log.error(error)
        this.$data.error = error.messages
      })
    }
  }
}
</script>


<template>
  <div v-if="isAdmin">
    <b-alert :show="currentItem.opened">
      <h5>
        <b-badge variant="success"><i class="fas fa-check"></i>現在、公開されています。</b-badge>
        <b-btn class="pull-right" size="sm" @click="onOpenModal">非公開にする</b-btn>
      </h5>
    </b-alert>
    <b-alert variant="warning" :show="!currentItem.opened">
      <h5>
        <b-badge variant="danger"><i class="fas fa-times"></i>現在、非公開となっています。</b-badge>
        <b-btn variant="success" class="ml-3" size="sm" @click="onOpenModal">公開する</b-btn>
        <b-btn variant="danger" class="pull-right" size="sm" @click="onDeleteModal">削除する</b-btn>
      </h5>
    </b-alert>
    <b-modal ref="editModal" size="md"
      :header-bg-variant="currentItem.opened ? 'danger' : 'success'"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title" v-if="currentItem.opened">
          <i :class="iconClass"></i> {{title}}-非公開
        </h5>
        <h5 class="modal-title" v-else>
          <i :class="iconClass"></i> {{title}}-公開
        </h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <form>
          <div class="form-group">
            <p v-if="currentItem.opened">この施設を<strong>非公開</strong>にします。</p>
            <p v-else>この施設を<strong>公開</strong>します。</p>
          </div>
        </form>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="danger" class="mr-2" @click="onClose" v-if="currentItem.opened">非公開にする</b-btn>
          <b-btn variant="primary" class="mr-2" @click="onOpen" v-else>公開する</b-btn>
          <b-btn variant="secondary" @click="$refs.editModal.hide()">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
    <b-modal ref="deleteModal" size="md"
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title">
          <i :class="iconClass"></i> {{title}}-削除
        </h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <b-form-group>
          <p>この施設情報「{{currentItem.name}}」を<strong>削除</strong>します。削除すると元には戻せません。</p>
          <p>確認のため、この施設の名称を入力してください。</p>
          <b-form-input type="text" class="form-control" v-model="deleteTarget" placeholder="名称を入力してください"></b-form-input>
        </b-form-group>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="danger" class="mr-2" @click="onClose" v-if="currentItem.opened">非公開にする</b-btn>
          <b-btn variant="primary" class="mr-2" @click="onDelete" v-else>削除する</b-btn>
          <b-btn variant="secondary" @click="$refs.deleteModal.hide">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { categoryTitle, categoryIcon, categoryModelClassName } from '@/utils/CategoryUtils'

export default {
  name: 'openButtons',
  data () {
    return {
      id: this.$route.params.id,
      deleteTarget: '',
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'currentItem',
      'isAdmin',
      'category'
    ]),
    title: function () {
      return categoryTitle(this.category)
    },
    iconClass: function () {
      return categoryIcon(this.category)
    }
  },
  methods: {
    onOpenModal: function () {
      this.$refs['editModal'].show()
    },
    onOpen: function () {
      var updateItem = _.cloneDeep(this.currentItem)
      Object.assign(updateItem, { opened: true })
      this.$store.dispatch('updateItem', { item: updateItem })
      this.error = null
      this.$refs['editModal'].hide()
      this.$bvToast.toast('施設情報を公開しました。', {variant: 'success'})
    },
    onClose: function () {
      var updateItem = _.cloneDeep(this.currentItem)
      Object.assign(updateItem, { opened: false })
      this.$store.dispatch('updateItem', { item: updateItem })
      this.error = null
      this.$refs['editModal'].hide()
      this.$bvToast.toast('施設情報を非公開にしました。', {variant: 'success'})
    },
    onDeleteModal: function () {
      this.deleteTarget = ''
      this.error = null
      this.$refs['deleteModal'].show()
    },
    validate: function () {
      this.error = null
      if (!this.deleteTarget) {
        this.error = '名称を入力してください'
      } else if (this.deleteTarget !== this.currentItem.name) {
        this.error = '名称が異なります。'
      }
    },
    onDelete: function () {
      this.validate()
      if (this.error) return
      this.$store.dispatch('deleteItem', {
        category: this.currentItem.category_id,
        id: this.currentItem.id
      }).then(() => {
        this.$refs['deleteModal'].hide()
        this.$bvToast.toast('施設情報を削除しました。', {variant: 'success'})
        this.$router.replace({ name: categoryModelClassName(this.$store.getters.category) })
      }).catch((error) => {
        this.$log.error(error)
        this.$data.error = error.messages
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
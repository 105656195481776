<template>
  <footer>
    <b-row class="mt-3 border-top">
      <b-col cols="12">
        <p class="text-center mt-3 copyright">&copy; 2021 Isehara city All Rights Reserved.</p>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: 'footerComp',
  data () {
    return {}
  }
}
</script>

<style scoped>
.copyright {
  font-size: 0.9em;
}
</style>

<!-- app -->
<template>
  <div v-if="isEditable">
    <div>
      <b-btn variant="primary" size="sm" @click="openModal"><i class="fas fa-pencil-alt mr-1"></i> 編集</b-btn>
    </div>
    <b-modal ref="editModal" size="xl"
      header-bg-variant="success"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title"><i :class="iconClass"></i> {{title}}-編集 [連携情報]</h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <b-form horizontal>
          <b-form-group label-for="contact" label="連絡窓口" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="contact" type="text" class="form-control" v-model="contact.name" placeHolder="氏名ほか"></b-input>
          </b-form-group>
          <b-form-group label-for="memo" label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-form-textarea v-model="contact.memo" rows="3" placeholder="連絡時の注意事項など"></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="primary" class="mr-2" @click="onSave">保存して更新</b-btn>
          <b-btn variant="secondary" @click="onClose">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { categoryTitle, categoryIcon } from '@/utils/CategoryUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'contactEditModalButton',
  components: {
  },
  data () {
    return {
      error: null,
      showModal: true,
      contact: {
        name: '',
        memo: ''
      },
      cooperation_priority: {
        tel: 1,
        fax: 2
      },
      priority_tel: true,
      priority_fax: false
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem',
      'currentUser'
    ]),
    title: function () {
      return categoryTitle(this.$store.getters.category)
    },
    iconClass: function () {
      return categoryIcon(this.$store.getters.category)
    },
    isEditable: function () {
      return this.isAdmin || (this.currentUser.permits && this.currentUser.permits[this.currentItem.id])
    }
  },
  methods: {
    loadItem: function () {
      this.error = null
      Object.assign(this.contact, this.currentItem.contact)
      Object.assign(this.cooperation_priority, this.currentItem.cooperation_priority)
      this.priority_tel = this.cooperation_priority.tel === 1
      this.priority_fax = this.cooperation_priority.fax === 1
    },
    onPriorityChanged: function () {
      this.$data['priority_tel'] = !this.$data['priority_tel']
      this.$data['priority_fax'] = !this.$data['priority_fax']
    },
    openModal: function () {
      this.loadItem()
      this.$refs['editModal'].show()
    },
    validate: function () {
      this.error = null
      this.cooperation_priority.tel = this.priority_tel ? 1 : 2
      this.cooperation_priority.fax = this.priority_fax ? 1 : 2
    },
    onSave: function () {
      this.validate()
      if (this.error) return
      var _item = _.cloneDeep(this.currentItem)
      Object.assign(_item.contact, this.contact)
      // Object.assign(_item.cooperation_priority, this.cooperation_priority)

      this.$log.info('updating', _item)
      this.$store.dispatch('updateItem', { item: _item }).then(() => {
        this.$log.info('updated!')
        this.$refs['editModal'].hide()
        this.$emit('onUpdated')
      }).catch((error) => {
        this.$log.error(error)
        this.$data.error = error.messages
      })
    },
    onClose: function () {
      this.$refs['editModal'].hide()
    }
  }
}
</script>

<style scoped>
.vue-js-switch.priority {
  font-size: 14px !important;
  margin-top: 3px;
}
</style>
const WEEKDAYS = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'public_holiday' ]
const WEEKDAYS_JP = [ '月', '火', '水', '木', '金', '土', '日', '祝' ]

const describeSummary = (businessDays) => {
  const biz = businessDays

  let full = []
  let half = []

  const f = (e) => ['2', 2].includes(e)
  const h = (e) => ['2', 2, '1', 1].includes(e)

  WEEKDAYS.forEach((wd, idx) => {
    const dd = [biz[wd].am, biz[wd].pm]
    const jp = WEEKDAYS_JP[idx]
    dd.every(f) ? full.push(jp) : (dd.some(h) ? half.push(jp) : null)
  })
  if (full.length <= 0 && half.length <= 0) return '??'

  const fs = full.length > 0 ? [full.join(''), '◯'] : ''
  const hs = half.length > 0 ? [half.join(''), '☆'] : ''
  return [fs, (full.length > 0 && half.length > 0 ? '/' : ''), hs].flat().join('')
}

const describeHalfSummary = (businessDays) => {
  const biz = businessDays

  let full = []
  let half = []

  const f = (e) => ['2', 2].includes(e)
  const h = (e) => ['1', 1].includes(e)

  WEEKDAYS.forEach((wd, idx) => {
    const dd = [biz[wd].am]
    const jp = WEEKDAYS_JP[idx]
    dd.every(f) ? full.push(jp) : (dd.some(h) ? half.push(jp) : null)
  })

  if (full.length <= 0 && half.length <= 0) return '??'

  const fs = full.length > 0 ? [full.join(''), '◯'] : ''
  const hs = half.length > 0 ? [half.join(''), '☆'] : ''
  return [fs, (full.length > 0 && half.length > 0 ? '/' : ''), hs].flat().join('')
}

export { describeSummary, describeHalfSummary }

<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr class="text-center">
        <th>受付</th>
        <th>月</th>
        <th>火</th>
        <th>水</th>
        <th>木</th>
        <th>金</th>
        <th>土</th>
        <th>日</th>
        <th>祝</th>
        </tr>
    </thead>
    <tbody>
      <tr class="text-center">
        <th class="text-left">{{fh(item.business_days.business_hours.am)}}</th>
        <td>{{fh(item.business_days.monday.am)}}</td>
        <td>{{fh(item.business_days.tuesday.am)}}</td>
        <td>{{fh(item.business_days.wednesday.am)}}</td>
        <td>{{fh(item.business_days.thursday.am)}}</td>
        <td>{{fh(item.business_days.friday.am)}}</td>
        <td>{{fh(item.business_days.saturday.am)}}</td>
        <td>{{fh(item.business_days.sunday.am)}}</td>
        <td>{{fh(item.business_days.public_holiday.am)}}</td>
      </tr>
      <tr class="text-center" v-if="half !== true">
        <th class="text-left">{{fh(item.business_days.business_hours.pm)}}</th>
        <td>{{fh(item.business_days.monday.pm)}}</td>
        <td>{{fh(item.business_days.tuesday.pm)}}</td>
        <td>{{fh(item.business_days.wednesday.pm)}}</td>
        <td>{{fh(item.business_days.thursday.pm)}}</td>
        <td>{{fh(item.business_days.friday.pm)}}</td>
        <td>{{fh(item.business_days.saturday.pm)}}</td>
        <td>{{fh(item.business_days.sunday.pm)}}</td>
        <td>{{fh(item.business_days.public_holiday.pm)}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'businessDaysTableComp',
  props: {
    half: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'currentItem'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    }
  },
  methods: {
    fh: function (m) {
      return [ '-', '☆', '◯' ][m] || m
    }
  }
}
</script>

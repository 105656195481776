export default {
  items: {
    hospitals: {
      name: 'hospital',
      model: 'hospitals',
      short_name: 'hospital',
      short_model: 'hospitals',
      title: '病院',
      director: '院長'
    },
    clinics: {
      name: 'clinic',
      model: 'clinics',
      short_name: 'clinic',
      short_model: 'clinics',
      title: '診療所',
      director: '院長'
    },
    dentalOffices: {
      name: 'dentalOffice',
      model: 'dentalOffices',
      short_name: 'dentaloffice',
      short_model: 'dentaloffices',
      title: '歯科',
      director: '院長'
    },
    pharmacies: {
      name: 'pharmacy',
      model: 'pharmacies',
      short_name: 'pharmacy',
      short_model: 'pharmacies',
      title: '薬局',
      director: '代表者'
    },
    visitingNursingStations: {
      name: 'visitingNursingStation',
      model: 'visitingNursingStations',
      short_name: 'visitnurse',
      short_model: 'visitnurses',
      title: '訪問看護ステーション',
      director: '代表者'
    },
    homeVisitCareSupportServices: {
      name: 'homeVisitCareSupportService',
      model: 'homeVisitCareSupportServices',
      short_name: 'homecare',
      short_model: 'homecares',
      title: '居宅介護支援事業所',
      director: '代表者'
    },
    visitingCareServices: {
      name: 'visitingCareService',
      model: 'visitingCareServices',
      short_name: 'visitcare',
      short_model: 'visitcares',
      title: '訪問介護事業所',
      director: '代表者'
    },
    rehabilitationServices: {
      name: 'rehabilitationService',
      model: 'rehabilitationServices',
      short_name: 'rehabilitation',
      short_model: 'rehabilitations',
      title: '通所リハビリテーション',
      director: '代表者'
    },
    dayCareSupportServices: {
      name: 'dayCareSupportService',
      model: 'dayCareSupportServices',
      short_name: 'daycare',
      short_model: 'daycares',
      title: '通所介護支援事業所',
      director: '代表者'
    },
    communityBasedSupportCenters: {
      name: 'communityBasedSupportCenter',
      model: 'communityBasedSupportCenters',
      short_name: 'community',
      short_model: 'communities',
      title: '地域包括支援センター',
      director: '代表者'
    },
    smallMultiHomeCareSupportServices: {
      name: 'smallMultiHomeCareSupportService',
      model: 'smallMultiHomeCareSupportServices',
      short_name: 'smallhomecare',
      short_model: 'smallhomecares',
      title: '小規模多機能型居宅介護',
      director: '代表者'
    },
    smallMultiHomeNurseServices: {
      name: 'smallMultiHomeNurseService',
      model: 'smallMultiHomeNurseServices',
      short_name: 'smallmultihomenurse',
      short_model: 'smallmultihomenurses',
      title: '看護小規模多機能居宅支援事業所',
      director: '代表者'
    },
    specialElderlyNursingHomes: {
      name: 'specialElderlyNursingHome',
      model: 'specialElderlyNursingHomes',
      short_name: 'eldershome',
      short_model: 'eldershomes',
      title: '短期入所生活介護（ショートステイ）',
      director: '代表者'
    },
    geriatricHealthServicesFacilities: {
      name: 'geriatricHealthServicesFacility',
      model: 'geriatricHealthServicesFacilities',
      short_name: 'grhealth',
      short_model: 'grhealthes',
      title: '短期入所療養介護（ショートステイ）',
      director: '代表者'
    },
    hiratsukas: {
      name: 'hiratsuka',
      model: 'hiratsukas',
      short_name: 'hiratsuka',
      short_model: 'hiratsukas',
      title: '平塚保健福祉事務所秦野センター',
      director: '代表者'
    }
  },
  groups: {
    medicals: [
      'hospitals',
      'clinics',
      'dentalOffices',
      'pharmacies'
    ],
    nursings: [
      'visitingNursingStations',
      'homeVisitCareSupportServices',
      'visitingCareServices',
      'rehabilitationServices',
      'dayCareSupportServices',
      'communityBasedSupportCenters',
      'smallMultiHomeCareSupportServices',
      'smallMultiHomeNurseServices'
    ],
    others: [
      'specialElderlyNursingHomes',
      'geriatricHealthServicesFacilities',
      'hiratsukas'
    ]
  }
}

<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col id="loginbox" cols="12" md="8" sm="12" lg="6" class="mt-5">
        <b-card :header="title" header-bg-variant="info" header-tag="h5" header-class="text-white" no-body>
          <b-card-body>
            <h5>ご利用申請フォーム</h5>
            <p class="small">
              本Webサイトのご利用の申請を行います。<br>
              下記に必要事項を入力し、「送信する」を押してください。<br>
              なお、ご記入頂いた内容は、本Webサイトの利用以外の目的には使用しません。
            </p>
            <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
            <b-form horizontal @submit.stop.prevent>
              <b-form-group for="name" label="お名前 (*必須)">
                <b-form-input id="name" type="text" v-model="register.name" placeholder="お名前" :state="register.isValidName"></b-form-input>
              </b-form-group>
              <b-form-group for="company" label="組織名または会社名 (*必須)">
                <b-form-input id="company" type="text" v-model="register.company" placeholder="組織名または会社名" :state="register.isValidCompany"></b-form-input>
              </b-form-group>
              <b-form-group for="email" label="メールアドレス (*必須)">
                <b-form-input id="email" type="email" v-model="register.email" placeholder="メールアドレス" :state="register.isValidEmail"></b-form-input>
              </b-form-group>
              <b-form-group for="password" label="パスワード (*必須)">
                <b-form-input id="password" type="password" v-model="register.password" placeholder="8文字以上" :state="register.isValidPassword"></b-form-input>
              </b-form-group>
              <div class="mt-4">
                <b-btn variant="warning" :disabled="loading" @click.prevent="onRegister">
                  <b-spinner variant="light" small v-if="loading"></b-spinner>
                  <span v-else>
                    <i class="fas fa-lg fa-hand-point-right"></i>
                    送信する
                  </span>
                </b-btn>
              </div>
              <div class="float-right">
                <router-link to="login">戻る</router-link>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Firebase from '@/api/firebase'

export default {
  name: 'hello',
  data () {
    return {
      title: '伊勢原市 在宅医療と介護連携情報WEB',
      loading: false,
      error: null,
      register: {
        email: null,
        password: null,
        name: null,
        company: null,
        isValidEmail: null,
        isValidPassword: null,
        isValidName: null,
        isValidCopmpany: null
      }
    }
  },
  methods: {
    validateEmail: function (email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)
    },
    validatePassword: function (password) {
      return !(!(password && password.trim())) && password.trim().length > 7
    },
    validateName: function (name) {
      return !(!(name && name.trim()))
    },
    validateCompany: function (company) {
      return !(!(company && company.trim()))
    },
    onRegister: function (event) {
      if (event) event.preventDefault()
      this.$data.register.isValidName = this.validateName(this.$data.register.name)
      this.$data.register.isValidCompany = this.validateCompany(this.$data.register.company)
      this.$data.register.isValidEmail = this.validateEmail(this.$data.register.email)
      this.$data.register.isValidPassword = this.validatePassword(this.$data.register.password)

      var valid = false
      if (!this.$data.register.isValidName) {
        this.error = '名前を入力してください。(R)'
      } else if (!this.$data.register.isValidCompany) {
        this.error = '会社名または組織名を入力してください。(R)'
      } else if (!this.$data.register.isValidEmail) {
        this.error = 'メールアドレスを正しく入力してください。(R)'
      } else if (!this.$data.register.isValidPassword) {
        this.error = 'パスワードを8文字以上で入力してください。(R)'
      } else {
        this.error = null
        valid = true
      }
      if (!valid) return

      this.loading = true
      this.$log.debug('onRegister', this.$data.email)
      // var that = this
      this.$store.dispatch(
        'createUserWithEmailAndPassword',
        { email: this.$data.register.email,
          password: this.$data.register.password,
          name: this.$data.register.name,
          company: this.$data.register.company }
        ).then(() => {
          this.$log.debug('UserRegistered')
          this.$router.replace('/registeruser/done')
        }).catch((error) => {
          this.loading = false
          this.$log.debug('error', error)
          this.$log.debug(Firebase._translateMessage(error.code))
          this.$data.error = Firebase._translateMessage(error.code)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#signupbox { margin-top: 50px; }
.input-group { margin-bottom: 25px; }
.panel-body { padding-top:30px; }

</style>

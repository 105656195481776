<template>
  <div class="table-responsive-sm">
    <table class="table table-bordered table-striped">
      <thead>
        <tr class="text-center">
          <th scope="col">月</th>
          <th scope="col">火</th>
          <th scope="col">水</th>
          <th scope="col">木</th>
          <th scope="col">金</th>
          <th scope="col">土</th>
          <th scope="col">日</th>
          <th scope="col">祝</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>
            <BusinessDaySelector v-model="business_days.monday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.tuesday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.wednesday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.thursday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.friday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.saturday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.sunday.am"></BusinessDaySelector>
          </td>
          <td>
            <BusinessDaySelector v-model="business_days.public_holiday.am"></BusinessDaySelector>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BusinessDaySelector from '@/components/modules/BusinessDaySelectorComp'

export default {
  name: 'businessWeekSelector',
  props: {
    'business_days': Object,
    half: {
      type: Boolean, required: false, default: false
    }
  },
  components: {
    BusinessDaySelector
  },
  data () {
    return {
    }
  },
  methods: {
    input (val) {
      this.$log.debug(val)
    }
  }
}
</script>

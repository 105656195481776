<template>
  <div v-if="isEditable">
    <div>
      <b-btn variant="primary" size="sm" @click="openModal"><i class="fas fa-pencil-alt mr-1"></i> 編集</b-btn>
    </div>
    <b-modal ref="editModal" size="xl"
      header-bg-variant="success"
      header-text-variant="white"
    >
      <template #modal-title>
        <h5 class="modal-title"><i :class="iconClass"></i> {{title}}-編集 [サービス]</h5>
      </template>
      <b-container fluid>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
        <b-form horizontal>
          <b-form-group label-for="business_days" label="受付時間" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-input id="business_days_am" type="text" class="form-control" v-model="business_days.business_hours.am" placeHolder="例) 10:00-12:30"></b-input>
          </b-form-group>
          <b-form-group label-for="business_days" label="営業日" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <p>{{business_days.summary}}</p>
            <BusinessWeekSelector :half="true" :business_days="business_days"></BusinessWeekSelector>
          </b-form-group>
          <b-form-group label-for="business_days_memo" label="営業日備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-form-textarea id="business_days_memo" v-model="business_days.memo" rows="3"></b-form-textarea>
          </b-form-group>
          <hr>
          <b-form-group label-for="services_memo" label="24時間連絡対応" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th>対応</th>
                  <th>備考</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td><ServiceSelector v-model="services.service_for_24h"></ServiceSelector></td>
                  <td><input id="summary" type="text" class="form-control" v-model="services.service_for_24h_memo" placeHolder="100文字以内">
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="help-block small">
              ※ ◯：対応可能、△：応相談、☓：対応不可<br>
              ※ 24時間の連絡の取れる体制の有無
            </p>
          </b-form-group>
          <b-form-group label-for="services_memo" label="特定事業所加算" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th>有無</th>
                  <th>加算型</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td><ServiceSelector v-model="services.medical_score"></ServiceSelector></td>
                  <td>
                    <select class="form-control service" v-model="services.medical_score_class">
                      <option value=""></option>
                      <option value="1">I型</option>
                      <option value="2">II型</option>
                      <option value="3">III型</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="help-block small">
              ※ ◯：対応可能、△：応相談、☓：対応不可<br>
              ※ 主任ケアマネの配置と複数のケアマネが所属している事業所で、他要件を満たしている事業所が算定できるものです。（要件によりⅠ～Ⅲがあり）
            </p>
          </b-form-group>
          <b-form-group label-for="services_memo" label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
            <b-form-textarea id="services_memo" v-model="services.memo" rows="3"></b-form-textarea>
            <p class="help-block small">
              ※連絡する際の要望、注意点、病院の特定など、ケアマネージャーに伝えたいことなど
            </p>
          </b-form-group>
        </b-form>
      </b-container>
      <template #modal-footer>
        <div class="float-right">
          <b-btn variant="primary" class="mr-2" @click="onSave">保存して更新</b-btn>
          <b-btn variant="secondary" @click="$refs.editModal.hide">キャンセル</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { categoryTitle, categoryIcon } from '@/utils/CategoryUtils'
import { mapGetters } from 'vuex'
import BusinessWeekSelector from '@/components/modules/BusinessWeekSelector'
import ServiceSelector from '@/components/modules/ServiceSelector'
import { describeHalfSummary } from '@/utils/businessDaysUtils'

export default {
  name: 'homeVisitCareSupportServiceServiceEditModalButton',
  components: {
    BusinessWeekSelector,
    ServiceSelector
  },
  data () {
    return {
      error: null,
      showModal: true,
      services: {
        summary: '',
        memo: ''
      },
      business_days: {
        summary: '',
        business_hours: { am: '', pm: '' },
        monday: { am: '', pm: '' },
        tuesday: { am: '', pm: '' },
        wednesday: { am: '', pm: '' },
        thursday: { am: '', pm: '' },
        friday: { am: '', pm: '' },
        saturday: { am: '', pm: '' },
        sunday: { am: '', pm: '' },
        public_holiday: { am: '', pm: '' },
        memo: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem',
      'currentUser'
    ]),
    title: function () {
      return categoryTitle(this.$store.getters.category)
    },
    iconClass: function () {
      return categoryIcon(this.$store.getters.category)
    },
    isEditable: function () {
      return this.isAdmin || (this.currentUser.permits && this.currentUser.permits[this.currentItem.id])
    }
  },
  mounted: function () {
    this.loadItem()
  },
  methods: {
    loadItem: function () {
      this.error = null
      this.services = _.cloneDeep(this.currentItem.services)
      this.business_days = _.cloneDeep(this.currentItem.business_days)
    },
    openModal: function () {
      this.loadItem()
      this.$refs['editModal'].show()
    },
    validate: function () {
      this.error = null
      this.business_days.summary = describeHalfSummary(this.business_days)
    },
    onSave: function () {
      this.validate()
      if (this.error) return
      var _item = _.cloneDeep(this.currentItem)
      _item.services = this.services
      _item.business_days = this.business_days

      this.$log.info('updating', _item)
      this.$store.dispatch('updateItem', { item: _item }).then(() => {
        this.$log.info('updated!')
        this.$refs['editModal'].hide()
      }).catch((error) => {
        this.$log.error(error)
        this.$data.error = error.messages
      })
    }
  }
}
</script>

<style scoped>
.vue-js-switch.priority {
  font-size: 14px !important;
  margin-top: 3px;
}
</style>
<template>
  <div>
    <NavbarHeaderComp></NavbarHeaderComp>
    <div class='container'>
      <div class='page-header' id='banner'>
        <BreadCrumbList :screen='screen'></BreadCrumbList>
        <Buttons :onRegistered="refresh"></Buttons>
      </div>
      <FacilityList csvType="hiratsuka" ref="list"></FacilityList>
      <FooterComp></FooterComp>
    </div>
  </div>
</template>

<script>
import NavbarHeaderComp from '@/components/NavbarHeader'
import BreadCrumbList from '@/components/modules/BreadCrumbList'
import Buttons from '@/components/modules/RegisterModalButton'
import FacilityList from '@/components/FacilityList'
import FooterComp from '@/components/modules/FooterComp'

export default {
  name: 'hiratsukas',
  data () {
    return {
      category: 'hiratsukas',
      screen: '一覧'
    }
  },
  components: {
    NavbarHeaderComp,
    BreadCrumbList,
    Buttons,
    FacilityList,
    FooterComp
  },
  methods: {
    refresh (msg) {
      this.$bvToast.toast(msg || '施設を登録しました。[G]')
      this.$refs.list.refresh && this.$refs.list.refresh()
    }
  },
  created () {
    this.$store.dispatch('changeCategory', this.$data.category)
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>

</style>

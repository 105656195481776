<template>
  <div class="container">
    <div id="mainbox" class="mainbox col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">
      <h3>{{title}}</h3>
      <div class="panel panel-warning" >
        <div class="panel-heading">
          <h4>このWEBサイトについて</h4>
          <h5>
            <pre>
              XXXXXXXXXXXXX
            </pre>
          </h5>
          <router-link to='/login' replace class="btn btn-default">戻る</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data () {
    return {
      title: '伊勢原市 在宅医療と介護連携情報WEB'
    }
  }
}
</script>

<style scoped>
#mainbox { margin-top: 50px; }
</style>
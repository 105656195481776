import uuid from 'uuid/v5'
import moment from 'moment'
import _ from 'lodash'

const SKELTON = {
  'id': '',
  'category_id': '',
  'category': {
    'id': '',
    'name': ''
  },
  'name': '',
  'management_corporation': '',
  'opened': false,
  'created_at': '',
  'updated_at': '',
  'address_summary': '神奈川県伊勢原市',
  'address': {
    'prefecture': '神奈川県',
    'line1': '伊勢原市',
    'line2': '',
    'line3': '',
    'zipcode': '',
    'latitude': '',
    'longitude': ''
  },
  'director_name': '',
  'tel': '',
  'tel_section': '',
  'fax': '',
  'fax_section': '',
  'email': '',
  'email_section': '',
  'contact': {
    'name': '',
    'position': ''
  },
  'cooperation_priority': {
    'tel': 1,
    'fax': 2
  },
  'url': '',
  'services': {
    'summary': '',
    'memo': ''
  },
  'business_days': {
    'summary': '',
    'business_hours': {
      'am': '',
      'pm': ''
    },
    'monday': {
      'am': '',
      'pm': ''
    },
    'tuesday': {
      'am': '',
      'pm': ''
    },
    'wednesday': {
      'am': '',
      'pm': ''
    },
    'thursday': {
      'am': '',
      'pm': ''
    },
    'friday': {
      'am': '',
      'pm': ''
    },
    'saturday': {
      'am': '',
      'pm': ''
    },
    'sunday': {
      'am': '',
      'pm': ''
    },
    'public_holiday': {
      'am': '',
      'pm': ''
    }
  },
  'view_order': 0,
  'memo': ''
}

export default {
  newItemFromSkelton (vals) {
    // debug && Vue.$log.debug('Faclity:newItemFromSkelton', vals)
    var newItem = _.merge(_.cloneDeep(SKELTON), vals)
    // debug && Vue.$log.debug('Faclity:newItemFromSkelton2', newItem)
    newItem.id = uuid(newItem.category_id + ':' + newItem.name, uuid.URL)
    newItem.created_at = moment().format('YYYY-MM-DD HH:mm:ssZ')
    return newItem
  }
}

<template>
  <div>
    <b-row>
      <b-col md="6">
        <BasicInfoPanel></BasicInfoPanel>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="bg-success text-white">
            <i class="fa fa-stethoscope" aria-hidden="true"></i><span> 診療</span>
            <div class="pull-right">
              <ServiceEditModalButton @onUpdated="refresh"></ServiceEditModalButton>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form horizontal>
              <b-form-group label="診療科目" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <div>{{item.services.summary}}</div>
              </b-form-group>
              <b-form-group label="専門科目" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <div>{{item.services.specialized_service}}</div>
              </b-form-group>
              <b-form-group label="診療日" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <BusinessDaysTable></BusinessDaysTable>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light">{{item.business_days.memo}}</b-card>
              </b-form-group>
            </b-form>
            <hr>
            <b-form horizontal>
              <b-form-group label="在宅医療等への対応状況" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <div class="font-weight-bold">在宅支援診療所</div>
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr class="small">
                      <th>対応</th>
                      <th>届出状況</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <ServiceView :value="item.services.home_visit_care_support"></ServiceView>
                      </td>
                      <td>
                        <span v-if="item.services.home_visit_care_support_type === '2'">
                          (2) 機能強化型
                        </span>
                        <span v-else-if="item.services.home_visit_care_support_type === '3'">
                          (3) 従来型
                        </span>
                        <span v-else>{{item.services.home_visit_care_support_type}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="help-block small">※関東甲信厚生局への届出状況</p>
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr class="small">
                      <th>居宅療養管理指導</th>
                      <th>訪問診療</th>
                      <th>外来かかりつけ患者の往診</th>
                      <th>主治医意見書</th>
                      <th>訪問看護指示書</th>
                      <th>麻薬取扱い</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td><ServiceView :value="item.services.guidance_at_home_care_management"></ServiceView></td>
                      <td><ServiceView :value="item.services.home_visit_service"></ServiceView></td>
                      <td><ServiceView :value="item.services.home_call"></ServiceView></td>
                      <td><ServiceView :value="item.services.my_doctors_opinion"></ServiceView></td>
                      <td><ServiceView :value="item.services.visiting_nursing_instruction"></ServiceView></td>
                      <td><ServiceView :value="item.services.drug_handling"></ServiceView></td>
                    </tr>
                  </tbody>
                </table>
                <p class="help-block small">※ ◯：対応可能、△：応相談、☓：対応不可</p>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light">{{item.services.memo}}</b-card>
                <p class="small mt-2">※連絡する際の要望、注意点、病院の特定など、ケアマネージャーに伝えたいことなど</p>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col lg="6">
        <googleMapComp></googleMapComp>
      </b-col>
      <b-col lg="6">
        <b-card header="更新履歴" header-class="bg-info text-white">
          <b-list-group>
            <b-list-group-item class="small">{{formatDate(item.created_at)}} 初期登録</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import BasicInfoPanel from '@/components/modules/BasicInfoPanelComp'
import BusinessDaysTable from '@/components/modules/BusinessDaysTableComp'
import ServiceEditModalButton from '@/components/modules/ClinicServiceEditModalButton'
import ServiceView from '@/components/modules/ServiceViewComp'
import GoogleMapComp from '@/components/modules/GoogleMapComp'

export default {
  name: 'clinicDetail',
  components: {
    BasicInfoPanel,
    BusinessDaysTable,
    ServiceEditModalButton,
    ServiceView,
    GoogleMapComp
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    }
  },
  methods: {
    formatDate (value, fmt = 'YYYY/MM/DD') {
      return (value == null) ? '--' : moment(value, 'YYYY-MM-DD').format(fmt)
    },
    refresh () {
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.form-group { margin-bottom: 5px; }
</style>


<template>
  <div>
    <NavbarHeaderComp></NavbarHeaderComp>
    <div class='container'>
      <div class='page-header' id='banner'>
        <BreadCrumbList :screen='screen'></BreadCrumbList>
      </div>
      <GoogleMapListComp></GoogleMapListComp>
      <FooterComp></FooterComp>
    </div>
  </div>
</template>

<script>
import NavbarHeaderComp from '@/components/NavbarHeader'
import BreadCrumbList from '@/components/modules/BreadCrumbList'
import GoogleMapListComp from '@/components/modules/GoogleMapListComp'
import FooterComp from '@/components/modules/FooterComp'

export default {
  name: 'geriatricHealthServicesFacilityMaps',
  data () {
    return {
      category: 'geriatricHealthServicesFacilities',
      screen: '地図'
    }
  },
  components: {
    NavbarHeaderComp,
    BreadCrumbList,
    GoogleMapListComp,
    FooterComp
  },
  created: function () {
    this.$store.dispatch('changeCategory', this.$data.category)
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <li class="list-group-item">
    <router-link class="list-group-item-content" :to="{ name: path }">
      <i :class="iconClass"></i> {{categoryTitle}}
      <b-badge pill>{{counts}}</b-badge>
    </router-link>
    <div class="list-group-item-controls">
      <router-link :to="mapsPath" class="btn btn-outline-secondary btn-sm">
        <i class="fas fa-map-marker-alt"></i> 地図
      </router-link>
    </div>
  </li>
</template>

<script>
import { categoryIcon, categoryModelClassName, categoryTitle, categoryShortName } from '@/utils/CategoryUtils'

export default {
  name: 'categoryPanelComp',
  props: [ 'category' ],
  data () {
    return {}
  },
  computed: {
    path: function () {
      return categoryModelClassName(this.$props.category)
    },
    mapsPath: function () {
      return '/' + categoryShortName(this.category) + '/maps'
    },
    iconClass: function () {
      return categoryIcon(this.category)
    },
    categoryTitle: function () {
      return categoryTitle(this.category)
    },
    counts: function () {
      var cat = this.$store.getters.categories[this.category]
      return cat ? cat.count : 0
    }
  },
  methods: {
    pushCategory: function () {
      this.$router.replace({ name: categoryModelClassName(this.$props.category) })
    }
  }
}
</script>

<style scoped>
i { padding-right: 0.3em; }
.badge { margin-left: 1em; }
li.list-group-item {
  padding: 0;
  position: relative;
  display: table;
  border-collapse: separate;
}
li.list-group-item .list-group-item-content {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  padding: 10px;
}
li.list-group-item .list-group-item-controls {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0 10px;
  text-align: center;
}
</style>
